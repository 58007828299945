import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { purple, red } from "@mui/material/colors";
import { Icon } from "@mui/material";
import JModal from "./JModal";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#0063cc",
  borderColor: "#0063cc",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const PrimaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "rgba(80,72,229,1)",
  "&:hover": {
    backgroundColor: "rgba(80,72,229,1)",
  },
  padding: "8px 20px",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "11pt",
  minWidth: "64px",
  maxHeight: "42px",
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeight: "600",
}));

const ErrorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: "rgba(220,20,60,1)",
  "&:hover": {
    backgroundColor: "rgba(220,20,60,1)",
  },
  padding: "8px 20px",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "11pt",
  minWidth: "64px",
  maxHeight: "42px",
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeight: "600",
}));

const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  variant: "outlined",
  backgroundColor: "rgba(255,255,255,1)",
  "&:hover": {
    backgroundColor: "rgba(255,255,255,1)",
  },
  color: "rgba(80,72,229,1)",
  padding: "8px 20px",
  textTransform: "none",
  borderRadius: "8px",
  borderColor: "rgba(80,72,229,1)",
  minWidth: "64px",
  fontSize: "11pt",
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeight: "600",
}));

export const JButtonProps = (caption, buttontype = "") => {};
//buttontype : primary, secondary

const JButton = (props) => {
  const [confirmationMessage, setConfirmationMessage] = useState(props.confirmationMessage);
  const [openModal, setOpenModal] = React.useState(false);
  const buttontype = props.buttontype == null ? "primary" : props.buttontype;
  const ShowIcon = props.showIcon == null ? false : props.showIcon;
  const iconName = props.iconName;
  const ShowConfirmation = props.showConfirmation;

  const onDoubleConfirm = (event) => {
    if (ShowConfirmation){
      setOpenModal(true);
    }
    else {
      props.onClick(event);
    }
  }

  const onClick = () => {
    props.onClick();
  }

  return (
    <Fragment>
      {buttontype == "primary" && (
        <PrimaryButton onClick={onDoubleConfirm}>
          <Stack direction='row' columnGap={1}>
          {ShowIcon && <Icon xs={{ color: 'white' }}>{iconName}</Icon>}
          {props.children}</Stack>
        </PrimaryButton>
      )}
      {buttontype == "secondary" && (
        <BackButton onClick={props.onClick} variant="outlined">
          {props.children}
        </BackButton>
      )}
      {buttontype == "error" && (
        <ErrorButton onClick={props.onClick} variant="outlined">
          {props.children}
        </ErrorButton>
      )}

{/* 
      <JModal openModal={openModal} setOpenModal={setOpenModal} title="Confirmation Message" onConfirm={onClick}>
          {confirmationMessage}
      </JModal> */}

    </Fragment>
  );
};

export default JButton;


import React, { Fragment, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const JDisplayText = (props) => 
{

return <Fragment>
     <Stack spacing={0.5}>
       <Typography sx={{
        fontWeight: "600", 
        fontSize: "12pt",
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
      }}>{props.caption}</Typography>
      
      <Typography sx={{
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
      }}>
        {props.children}
       </Typography></Stack>
    </Fragment>
}

export default JDisplayText;
import React, { Fragment, useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

const JCheckbox = (props) => {
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    setIsCheck(props.isCheck);
  },[props.isCheck]);

  const OnChecked = () => {
    props.setIsCheck(!isCheck);
    setIsCheck(!isCheck);
  };

  return (
    <Fragment>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Checkbox
          checked={isCheck}
          sx={{
            p: 0,
            fontFamily:
            'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',        
            color: "rgba(80,72,229,1)",
            "&.Mui-checked": {
              color: "rgba(80,72,229,1)",
            },
          }}
          onChange={OnChecked}
        />
        <Typography sx={{
          fontFamily:
          'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',        
        }}>{props.caption}</Typography>
      </Stack>
    </Fragment>
  );
};

export default React.memo(JCheckbox);

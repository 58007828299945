import { initStore } from './store';

const ConfigureStore = () => {
    const actions = {
        SetAccessRight: (curState, accessRight) => {
            console.log(accessRight);
            return { accessRight: accessRight };
        },
        SetMenuState: (curState, menuState) => {
            console.log(menuState);
            return { menuState: menuState };
        },
        SetAccessPage: (curState, accessPage) => {
            console.log(accessPage);
            return { accessPage: accessPage };
        },
        SetCompany: (curState, company) => {
            console.log(company);
            return { company: company };
        }               
    };
    initStore(actions, { accessRight: [], menuState: [], company: [], accessPage: [] });
};


export default ConfigureStore;
import React, { Fragment, useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import JCombobox from "./JCombobox";

const JTimePicker = (props) => {
  const initialValue =
    props.fieldName == null
      ? props.value
      : props.value.find((x) => x.fieldName == props.fieldName).value;

  const [Hours, setHours] = useState([]);
  const [selectedTime, setSelectedTime] = useState(initialValue);

  const GenerateTime = () => {
    let time = [];

    var hour;
    for (hour = 0; hour < 24; hour++) {
      let hour_string = hour;
      if (hour < 10) {
        hour_string = "0" + hour;
      }

      time.push({ id: hour_string + ":00", name: hour_string + ":00" });

      if (!props.halfHourOnly) {
          time.push({ id: hour_string + ":05", name: hour_string + ":05" });
          time.push({ id: hour_string + ":10", name: hour_string + ":10" });
          time.push({ id: hour_string + ":15", name: hour_string + ":15" });
          time.push({ id: hour_string + ":20", name: hour_string + ":20" });
          time.push({ id: hour_string + ":25", name: hour_string + ":25" });
      }

      time.push({ id: hour_string + ":30", name: hour_string + ":30" });

      if (!props.halfHourOnly) {
        time.push({ id: hour_string + ":35", name: hour_string + ":35" });
        time.push({ id: hour_string + ":40", name: hour_string + ":40" });
        time.push({ id: hour_string + ":45", name: hour_string + ":45" });
        time.push({ id: hour_string + ":50", name: hour_string + ":50" });
        time.push({ id: hour_string + ":55", name: hour_string + ":55" });
      }
    }

    setHours(time);
  };

  useEffect(() => {
    GenerateTime();
  }, []);

  useEffect(() => {

    if (props.fieldName == null) {
      props.setValue(selectedTime);
    } else {
      const data = props.value.find((e) => e.fieldName === props.fieldName);

      props.setValue((previous) => {
        return previous.filter((existingData) => existingData !== data);
      });

      data.value = selectedTime;

      props.setValue((previous) => {
        return [...previous, data];
      });

      console.log(data);
    }

  },[selectedTime])


  // const handleChange = (newValue) => {
  //   if (props.fieldName == null) {
  //     props.setValue(newValue);
  //   } else {
  //     const data = props.value.find((e) => e.fieldName === props.fieldName);

  //     props.setValue((previous) => {
  //       return previous.filter((existingData) => existingData !== data);
  //     });

  //     data.value = newValue;

  //     props.setValue((previous) => {
  //       return [...previous, data];
  //     });

  //     console.log(data);
  //   }
  // };

  const width = props.width == null ? "300" : props.width;
  const showDefault = props.allowNull == null ? false : props.allowNull;

  return (
    <Fragment>
      <Stack spacing={0.5}>
        <Typography sx={{
          textDecoration: "none",
        fontWeight: "600", 
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
      }}>{props.caption}</Typography>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField  {...params} hiddenLabel size="small"  sx={{ width: `${width}px` }} />}
            />
        </LocalizationProvider> */}
        <JCombobox
          dataSource={Hours}
          value={selectedTime}
          setValue={setSelectedTime}
          valueID="id"
          valueText="name"
          width={width}
          showCaption={false}
          showDefault={showDefault}
          defaultText="To be confirmed"
        ></JCombobox>
      </Stack>
    </Fragment>
  );
};

export default JTimePicker;

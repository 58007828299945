import React, { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from "@mui/material";
import ReactDom from "react-dom";

const JLoadingPanel  = (props) => {
    
    return <Fragment>
          {ReactDom.createPortal(
        <Dialog open={props.showLoadingPanel} sx={{ p: 5 }}>
            <DialogContent>
            <Stack direction="column"
                justifyContent="flex-end"
                alignItems="center" 
                spacing={2}>

                <CircularProgress  color="secondary" />                    
                <Typography alignItems="flex-end" justifyContent="center">Data Processing...</Typography>
            </Stack>
            </DialogContent>
        </Dialog>, document.getElementById("backdrop-root"))}
    </Fragment>

}

export default JLoadingPanel;
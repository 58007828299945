import { SendRequest } from "../hooks/new-http"

const ReservationApi = () => {
    
  const PostRequest = SendRequest();

  const ReservationInit = async (companyCode) => {

    var response = await PostRequest("Reservation/Init?companyCode=" + companyCode);
    return response;
  };

  const Search = async (postData) => {

    var response = await PostRequest("Reservation/Search", "POST", postData);

    return response;

  }

  const GetAvailabilityDate = async (postData) => {

    var response = await PostRequest("Reservation/GetAvailabilityDate", "POST", postData);

    return response;

  }

  
  const ConfirmReservation = async (postData) => {

    var response = await PostRequest("Reservation/ConfirmReservation", "POST", postData);

    return response;

  }

  const ProceedReservation =  async (companyCode, reservationNumber, sessionID) => {
    
    var response = await PostRequest("Reservation/BEProceedReservation?companyCode=" + companyCode + "&ReservationNumber=" + reservationNumber + "&sessionID=" + sessionID);
    return response;
  }

  const GetReservation =  async ( reservationID, companyCode) => {
    
    var response = await PostRequest("Reservation/GetReservation?companyCode=" + companyCode + "&ReservationID=" + reservationID);
    return response;
  }

  return { ReservationInit, Search, GetAvailabilityDate, ConfirmReservation, ProceedReservation, GetReservation };
};


export default ReservationApi;

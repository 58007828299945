import React, { useState, useEffect, useCallback } from 'react';
import Translater from '../languages/translater';


let logoutTimer;

const AuthContext = React.createContext({
    token: '',
    currentUserID: '',
    language: 'en',
    currencyID: 1,
    isLoggedIn: false,
    changeLanguage: (code) => {},
    changeCurrency: (id) => {},
    login: (token) => {},
    logout: () => {},
   // showNotification: (message, success) => {},   
    TicketServiceLocationList: [],
});

// const ShowNotification = (message, success = true) => {
//   const [dispatch] = useStore()[1];
//   dispatch("ShowNotification", true);
//   dispatch("NotificationMessage", message);
//   dispatch("ShowErrorMessage", success);

// }

const CalculateRemainingTime = (expirationTime) => {
  const CurrentTime = new Date().getTime();
  const AdjExpirationTime = new Date(expirationTime).getTime();

  const RemainingDuration = AdjExpirationTime - CurrentTime;

  return RemainingDuration;
}

const RetrieveStoredData = () =>
{
    const storedToken = localStorage.getItem('token');
    const storedExpirationTime = localStorage.getItem('expirationTime');
    const storedUserID = localStorage.getItem('userId');
    const storedCompanyCode = localStorage.getItem('companyCode');
    const storedLanguage = localStorage.getItem('language');
    const storedCurrency = localStorage.getItem('currency');

    const remainingTime = CalculateRemainingTime(storedExpirationTime);

    if(remainingTime <= 3600) {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
      return null;
    }

    return {
      token: storedToken,
      duration: remainingTime,
      userID: storedUserID,
      companyCode: storedCompanyCode,
      language: storedLanguage,
      currencyID: storedCurrency
    };

}

export const AuthContextProvider = (props) =>{
    //const initialToken = null;

    const [ticketServiceLocations, setTicketServiceLocations] = useState([]);
    const translater = Translater();

    const tokenData = RetrieveStoredData();
    let initialToken;
    let initialUserID;
    let initialCompanyCode = null;
    let initialLanguage = 'en';
    let initialCurrency = 1;
    
    if(tokenData) {
      initialToken = tokenData.token;
      initialUserID = tokenData.userID;
      initialCompanyCode = tokenData.companyCode;
      initialLanguage = tokenData.language;
      initialCurrency = tokenData.currencyID;
    }

    const [token, setToken] = useState(initialToken);
    const [language, setLanguage] = useState(initialLanguage);
    const [currencyID, setCurrencyID] = useState(initialCurrency);
    const [currentUserID, setCurrentUserID] = useState(initialUserID);
    const [companyCode, setCompanyCode] = useState(initialCompanyCode);
    const [signalConnectionID, setSignalConnectionID] = useState(null);
    const userIsLoggedIn = !!token;

    const PrepareBookingEngine = (props) => {
      // setTicketServiceLocations(props.tripTypes.find(x => x.id == 6).origins);
    }

    const companyCodeHandler = (code) => {
      localStorage.setItem('companyCode', code);   
      setCompanyCode(code);
    }

    const changeLanguage = (code) => {
      localStorage.setItem('language', code); 
      setLanguage(code);  
    };
    
    const changeCurrency = (id) => {
      localStorage.setItem('currency', id); 
      setCurrencyID(id);  
    };

    const loginHandler = (props) => {

      const expirationTime = new Date(new Date().getTime() + (props.expireDuration * 60 * 1000));
      setToken(props.accessToken);  
      setCurrentUserID(props.userId);
      localStorage.setItem('token', props.accessToken); 
      localStorage.setItem('userId', props.userId);  
      localStorage.setItem('expirationTime', expirationTime); 
      localStorage.setItem('language', 'en');  
    
      const remainingTime = CalculateRemainingTime(expirationTime);

      //logoutTimer = setTimeout(logoutHandler, remainingTime);

    };

    const logoutHandler = useCallback(() => {
        setToken(null);
        setCurrentUserID(null);
        setCompanyCode(null);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('companyCode');   
     
        
      if(logoutTimer) {
        clearTimeout(logoutTimer);
      }

    }, []);
    
    useEffect(() => 
    {
        if(tokenData) {
          console.log(tokenData.duration);
          //logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler]);

    const Language = [
      {
        code: "en",
        name: "English"
      },
      {
        code: "cn",
        name: "中文"
      }
    ]
 


    const globalVariable = {
      TripType_PickandDrop: 1,
      TripType_Transit: 2,
      TripType_Tour: 3,
      TripType_CarRental: 4,
      TripType_CarPool: 5,
      TripType_GroupService: 6,
      CustomerType_Passenger: 1,
      CustomerType_Corporate: 2,
      ScheduleCategory_Internal: 1,
      ScheduleCategory_Partner: 2,
      OrderSource_Direct: 1,
      OrderSource_Partner: 2,
      PassengerType_OrderedPassenger: 0,
      PassengerType_ExistingPassenger: 1,
      PassengerType_NewPassenger: 2,
      PassengerType_PassengerPlacard: 3,
      PassengerType_NoNeedContact: 4,
      BillType_Passenger: 1,
      BillType_Channel: 2,
      TripStatus_Pending: 1,
      TripStatus_Confirmed: 2,
      TripStatus_Completed: 3,
      TripStauts_Cancelled: 4,
      AreaType_AnyArea: 1,
      AreaType_SelectedArea: 2,
      LocationType_StateArea: 1,
      LocationType_GenericLocation: 2,
      LocationType_AttractionPlace: 4,
      TransitType_Arrival: 1,
      TransitType_Departure: 2,
    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        currentUserID: currentUserID,
        companyCode: companyCode,
        language: language,
        currencyID: currencyID,
        setCompanyCode: companyCodeHandler,
        changeLanguage: changeLanguage,
        changeCurrency: changeCurrency,
        login: loginHandler,
        logout: logoutHandler,
        //showNotification: ShowNotification,
        prepareBookingEngine: PrepareBookingEngine,
        TicketServiceLocationList: ticketServiceLocations,
        LanguageList: Language,
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
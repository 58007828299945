import { Fragment, useEffect, useState } from "react";
import JDatePicker from "../../Layout/JDatePicker";
import JFormItem from "../../Layout/JFormItem";
import JFormLayout from "../../Layout/JFormLayout";
import JTextbox from "../../Layout/JTextbox";
import { Paper, Stack, Icon, TextField, Box } from "@mui/material";
import JLabel from "../../Layout/JLabel";
import JButton from "../../Layout/JButton";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";

const ReservationLocationInfo = (props) => {

    const locations = props.locations;

    // useEffect(() => {
    //     if (props.locations.length == 0) {
    //         props.setLocations([{
    //             ID: Math.random().toString(),
    //             address: null
    //         }]);
    //     }
    //     else {
    //         props.setLocations(props.locations)
    //     }
    // }, props.locations);

    const onTextChange = (index, fieldname, inputValue) => {  
  
      ValueUpdate(index, fieldname, inputValue);
    };
  
    const ValueUpdate = (index, fieldname, value) => {
      let sourcelist = locations;
  
      //Find the row
      let rowData = sourcelist[index];
      //Replace Value
      rowData[fieldname] = value;
      //Overwrite the existing row
      sourcelist[index] = rowData;
  
      console.log(sourcelist);
  
      //set the datasource
      props.setLocations([...sourcelist]);
    };
  
   const onClick =(id) => {

      let newlocationList = locations.filter(
        (x) => x.ID !== id
      );  

      props.setLocations([...newlocationList]);
    }
  
    const onAddLocation = async () => {   
  
      let newlocationList = [...locations, {
        ID: Math.random().toString(),
        address: null,
      }];
  
      props.setLocations([...newlocationList]);  
    };
    
    return <Fragment>
             
        <JFormLayout>
            <JFormItem>
            <Stack direction="row" spacing={1}>
              {/* <Icon sx={{ color: "#6b7280" }}>{props.iconName}</Icon> */}
            
            <JLabel size={12}>{props.title}</JLabel>
            <Box sx={{ flex: "1 1 auto" }} />
            <JButton onClick={onAddLocation}>More Location</JButton>
            </Stack>
            </JFormItem>
 
            {locations.map((location, index) => (
                <JFormItem>
                    {/* <JTextbox caption="Pickup Address" multipleline={true}></JTextbox> */}
                    <TextField
                        sx = {{ width: { xs: "100%", md: `500px` }
                        }}
                        hiddenLabel
                        multiline={true}
                        rows={2}
                        value={location.address}
                        onChange={(event) => onTextChange(index, "address", event.target.value)}
                    >                        
                    </TextField>
                    {locations.length > 1 && <IconButton edge="end" aria-label="cancel" onClick={() => onClick(location.ID)}>
                    <CancelIcon color="error" />
                  </IconButton>}
                </JFormItem>
            ))}

           
         
 
        </JFormLayout>
    </Fragment>
}

export default ReservationLocationInfo;

import React, { Fragment, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const JLabel = (props) => 
{


  const fontSize = props.size == null ? "13pt" : props.size + "pt";
  const bold = props.bold == null ? "600" : (props.bold == true ? "600" : "0");

  return <Fragment>
       <Typography sx={{
        fontWeight: bold, 
        fontSize: fontSize,
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
      }}>{props.children}</Typography>
    </Fragment>
}

export default JLabel;
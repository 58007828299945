import React, { Fragment } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ReactDom from "react-dom";
import { useStore } from "../../hooks-store/store";


export const ShowNotification = (message, success = true) => {
  console.log('Hoseh bo?');
   //const [state, dispatch] = useStore();
  //  dispatch("ShowNotification", true);
  //  dispatch("NotificationMessage", message);
  //  dispatch("ShowErrorMessage", success);
}


const ShowSnackbar = (props) => {
  
  //const [state, dispatch] = useStore();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    // dispatch("ShowNotification", false);
    // dispatch("NotificationMessage", '');
    // dispatch("ShowErrorMessage", true);
    props.onCloseNotification();
  };


  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity= {props.showErrorMessage ? "error" : "success"}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {props.setNotificationMessage}
      </Alert>
    </Snackbar>
  );
};

const Notification = (props) => {

  const onCloseNotification = () => {

    props.onCloseNotification();
  }


  return (
    <Fragment>
      {ReactDom.createPortal(
        <ShowSnackbar
          open={props.open}
          onCloseNotification={onCloseNotification}
          setNotificationMessage={props.setNotificationMessage}
          showErrorMessage={props.showErrorMessage}
        ></ShowSnackbar>,
        document.getElementById("overlays-root")
      )}
    </Fragment>
  );
};

export default Notification;

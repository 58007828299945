import { Grid, Typography, Icon, IconButton } from "@mui/material";
import React, { Fragment, useState, useEffect, useContext } from "react";
import Stack from "@mui/material/Stack";
import JCombobox from "../../Layout/JCombobox";
import JDatePicker from "../../Layout/JDatePicker";
import JButton from "../../Layout/JButton";
import JLabel from "../../Layout/JLabel";
import JDisplayText from "../../Layout/JDisplayText";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ReservationApi from "../../../api/reservation-api";
import ReservationListItem from "./ReservationListItem";
import ReservationPassengerInfo from "./ReservationPassengerInfo";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { useStore } from "../../../hooks-store/store";
import AuthContext from "../../../store/auth-context";
import JCheckbox from "../../Layout/JCheckbox";
import ReservationPanel from "./ReservationPanel";
import JTextbox from "../../Layout/JTextbox";
import JFormLayout from "../../Layout/JFormLayout";
import JFormItem from "../../Layout/JFormItem";
import FaceIcon from "@mui/icons-material/Face";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment/moment";
import Translater from "../../../languages/translater";

const Reservation = (props) => {
  const TripSelection_OneWay = 1;
  const TripSelection_Return = 2;
  const [tripSelectionID, setTripSelectionID] = useState(TripSelection_Return);
  const [originID, setOriginID] = useState(null);
  const [destinationID, setDestinationID] = useState(null);
  const [departDate, setDepartDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [noOfPax, setNoOfPax] = useState(1);
  const [departureDatasource, setDepartureDatasource] = useState([]);
  const [returnDatasource, setReturnDatasource] = useState([]);
  const [passengerInfo, setPassengerInfo] = useState({
    name: null,
    contactNumber: null,
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [destination, setDestination] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reservationApi = ReservationApi();
  const id = queryParams.get("be");
  const reservationID = queryParams.get("rid");
  const [step, setStep] = useState(0);
  const [state, dispatch] = useStore();
  const authCtx = useContext(AuthContext);
  const [showSearchPanel, setShowSearchPanel] = useState(true);
  const [allowCashPayment, setAllowCashPayment] = useState(null);
  const [allowPaymentGateway, setAllowPaymentGateway] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [tripTypeName, setTripTypeName] = useState(null);
  const [origins, setOrigins] = useState([]);
  const [termCondition, setTermCondition] = useState(false);
  const [displayFromLocation, setDisplayFromLocation] = useState(null);
  const [displayToLocation, setDisplayToLocation] = useState(null);
  const [currencyName, setCurrencyName] = useState(null);
  const translater = Translater();
  const [departDates, setDepartDates] = useState([]);
  const [returnDates, setReturnDates] = useState([]);
  const [paymentData, setPaymentData] = useState({
    bookingEngineCode: state.currentBookingEngineCode,
    departureTrip: null,
    returnTrip: null,
    noOfPax: 1,
    passengerName: null,
    contactNumber: null,
    totalAmount: 0,
  });

  
  const TripSelection = [
    { id: TripSelection_Return, name: "Round-trip" },
    { id: TripSelection_OneWay, name: "One-way" },
  ];

  useEffect(() => {
    if (props.source != null) {
      setAllowCashPayment(props.source.allowCashPayment);
      setAllowPaymentGateway(props.source.allowPaymentGateway);
      setMinDate(moment().add(1, "day"));
      setMaxDate(moment().month(props.source.bookingInAdvanced));
      setTripTypeName(props.source.tripTypeName);
      setOrigins(props.source.origins ?? []);
      ReloadReservation(props.source.origins);
      
    }
  }, [props.source, reservationID]);

  const ReloadReservation = async (source) => {
    if (reservationID != null) {
      console.log("Return From Cancel Page");
      var response = await reservationApi.GetReservation(reservationID, state.currentBookingEngineCode);
      setStep(3);

      setTripSelectionID(response.data.tripSelectionID);
      setNoOfPax(response.data.noOfPax);
      setDepartDate(response.data.departureTrip.startDate);
      setReturnDate(response.data.returnTrip.startDate);
      setShowSearchPanel(false);

      console.log(response.data);
      //Replace the From and To
      var origin = source.find(x => x.countryStateAreaID === response.data.departureTrip.fromCountryStateAreaID && x.countryStateID === response.data.departureTrip.fromCountryStateID);
      setOriginID(origin.id);
      setDisplayFromLocation(origin.name);

      var destination = origin.destinations.find(x => x.countryStateAreaID === response.data.departureTrip.toCountryStateAreaID && x.countryStateID === response.data.departureTrip.toCountryStateID)
      setDestinationID(destination.id);
      setDisplayToLocation(destination.name);

      setPassengerInfo({
        name: response.data.passengerName,
        contactNumber: response.data.contactNumber,
        email: response.data.email
      });

      setPaymentData({
        ...paymentData,
        departureTrip: response.data.departureTrip,
        returnTrip: response.data.returnTrip,
        totalAmount: response.data.totalAmount,
      });

      setDepartureDatasource(response.data.departureList);
      setReturnDatasource(response.data.returnList);

    }
  }

  useEffect(() => {
    if (originID != null) {
      if (origins.find((x) => x.id === originID) == null) {
        setDestination([]);
      } else {
        setDestination(origins.find((x) => x.id === originID).destinations);
      }
    }
  }, [originID]);

  useEffect(() => {
    if (destinationID != null) {
      CheckAvailability();
    }
  }, [destinationID]);


  const CheckAvailability = async () => {

    let FromAreaID = null;
    let FromStateID = null;
    let ToAreaID = null;
    let ToStateID = null;
  
    if (originID == null) {
      dispatch("ShowNotification", { message: "Please select From", errormessage: true});
       return;
    } else {
      const origin = origins.find((x) => x.id === originID);
      FromAreaID = origin.countryStateAreaID;
      FromStateID = origin.countryStateID;
    }

    if (destinationID == null) {
      dispatch("ShowNotification", { message: "Please select To", errormessage: true});
       return;
    } else {
      const dest = destination.find((x) => x.id === destinationID);
      ToAreaID = dest.countryStateAreaID;
      ToStateID = dest.countryStateID;
    }

    const postData = {
      bookingEngineCode: state.currentBookingEngineCode,
      tripSelectionID: tripSelectionID,
      fromAreaID: FromAreaID,
      fromStateID: FromStateID,
      toAreaID: ToAreaID,
      toStateID: ToStateID,
      departDate: departDate,
      returnDate: returnDate,
      currencyID: authCtx.currencyID,
      noOfPax: noOfPax,
      getDate: true
    };

    if (process.env.REACT_APP_ENVIRONMENT) {
      console.log(postData);
    }

    var response = await reservationApi.GetAvailabilityDate(postData);

    if (response.success) {
      setDepartDates(response.data.departDates);
      setReturnDates(response.data.returnDates);
    }

    console.log(response.data);
  }


  const onSearch = async () => {
    let FromAreaID = null;
    let FromStateID = null;
    let ToAreaID = null;
    let ToStateID = null;
  
    if (originID == null) {
      dispatch("ShowNotification", { message: "Please select From", errormessage: true});
       return;
    } else {
      const origin = origins.find((x) => x.id === originID);
      FromAreaID = origin.countryStateAreaID;
      FromStateID = origin.countryStateID;
      setDisplayFromLocation(origin.name);
    }

    if (destinationID == null) {
      dispatch("ShowNotification", { message: "Please select To", errormessage: true});
       return;
    } else {
      const dest = destination.find((x) => x.id === destinationID);
      ToAreaID = dest.countryStateAreaID;
      ToStateID = dest.countryStateID;
      setDisplayToLocation(dest.name);
    }

    if (departDate == null) {
      dispatch("ShowNotification", { message: "Please select Depart Date", errormessage: true});
       return;
    }

    if (tripSelectionID == TripSelection_Return) {
      if (returnDate == null) {
        dispatch("ShowNotification", { message: "Please select Return Date", errormessage: true});
        return;
      }
    }

    const postData = {
      bookingEngineCode: state.currentBookingEngineCode,
      tripSelectionID: tripSelectionID,
      fromAreaID: FromAreaID,
      fromStateID: FromStateID,
      toAreaID: ToAreaID,
      toStateID: ToStateID,
      departDate: departDate,
      returnDate: returnDate,
      currencyID: authCtx.currencyID,
      noOfPax: noOfPax,
    };

    if (process.env.REACT_APP_ENVIRONMENT) {
      console.log(postData);
    }

    var response = await reservationApi.Search(postData);

    if (response.success) {
      setDepartureDatasource(response.data.departureList);
      setReturnDatasource(response.data.returnList);
      setStep(1);
      setShowSearchPanel(false);
      setCurrencyName(response.data.currencyName);
      setPaymentData({
        bookingEngineCode: state.currentBookingEngineCode,
        departureTrip: null,
        returnTrip: null,
        noOfPax: noOfPax,
        passengerName: null,
        contactNumber: null,
        totalAmount: 0,
      });
    }
  };

  const onNext = async (trip, type) => {

    if (trip.availableSlot < noOfPax) {
      dispatch("ShowNotification", { message: "Seat Insufficient for your selection. Please find other slot", errormessage: true});
      return;
    }


    if (type == "depart") {
      setPaymentData({
        ...paymentData,
        departureTrip: trip,
      });

      if (tripSelectionID == TripSelection_Return) {
        setStep(2);
      } else {
        setStep(3);
      }
    } else {
      setPaymentData({
        ...paymentData,
        returnTrip: trip,
      });
      setStep(3);
    }
  };

  useEffect(() => {
    if (paymentData != null) {
      console.log("Whats Wrong?")
      let totalAmount = 0;

      if (paymentData.departureTrip != null) {
        totalAmount = totalAmount + paymentData.departureTrip.totalAmount;
        console.log(totalAmount)
      }

      if(paymentData.returnTrip != null) {
        totalAmount = totalAmount + paymentData.returnTrip.totalAmount;
        console.log("Whats Wrong?")
      }

      setTotalAmount(totalAmount);    
    }
  }, [paymentData.departureTrip, paymentData.returnTrip]);

  const onConfirmReservation = async (ProceedPayment) => {
    
    if (passengerInfo.name == null) {
        dispatch("ShowNotification", { message: "Please fill in Full Name", errormessage: true});
        return;
    }

    if (passengerInfo.contactNumber == null) {
        dispatch("ShowNotification", { message: "Please fill in Contact Number", errormessage: true});
        return;
    }

    if (passengerInfo.agreement != true) {
      dispatch("ShowNotification", { message: "Please tick to agree the Term and Condition", errormessage: true});
      return;
    }

    let postData = {
      ...paymentData,
      contactNumber: passengerInfo.contactNumber,
      passengerName: passengerInfo.name,
      email: passengerInfo.email,
      tripTypeID: 5,
      currencyID: authCtx.currencyID,
      proceedPayment: ProceedPayment,
      noOfPax: noOfPax,
      reservationID: reservationID,
      totalAmount: totalAmount,

    };

    console.log(passengerInfo);
    console.log(postData);

    var response = await reservationApi.ConfirmReservation(postData);

    if (response.success) {
      if (ProceedPayment) {
        //history.push(response.data);
        window.location.replace(response.data);
      } else {
        //Confirmation Page
        history.push("carpool/success?be=" + state.currentBookingEngineCode + "&ref=" + response.key);
      }
    }
  };

  const onBack = (step) => {

    if (step == 3) {
      if (tripSelectionID == TripSelection_Return) {
        setStep(2);
      } else {
        setStep(1);
      }
    }
    else {
      setStep(1);
    }

  };

  return (
    <Fragment>
      <Stack spacing={1} sx={{ p: 1 }}>
        <JFormLayout>
          <JFormItem>
            <JLabel>{tripTypeName}</JLabel>
          </JFormItem>
          <JFormItem>
            <Paper sx={{ p: { xs:1, md:2 }, pt: { xs: 2 } }}>   
              <JFormLayout>
                <JFormItem>
                  <Stack direction="row" spacing={1}>
                    <Icon sx={{ color: "#6b7280" }}>search</Icon>
                    <JLabel>{translater.Translate("Search Trip")}</JLabel>
                  </Stack>
                </JFormItem>

                {showSearchPanel && (
                  <JFormItem>
                    <Grid container rowSpacing={2} flex>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                        caption={translater.Translate("Trip Type")}
                        dataSource={TripSelection}
                        valueID="id"
                        valueText="name"
                        value={tripSelectionID}
                        setValue={setTripSelectionID}
                        width="220"
                      ></JCombobox></Grid>
                      <Grid item xs={12} md={9}>
                        <JTextbox
                        caption={translater.Translate("No of Pax")}
                        value={noOfPax}
                        setValue={setNoOfPax}
                        type="number"
                        width="100px"
                      ></JTextbox>
                      </Grid>
                      <Grid item xs={12} md={3}> 
                        <JCombobox
                          caption={translater.Translate("From")}
                          dataSource={origins}
                          valueID="id"
                          valueText="name"
                          value={originID}
                          setValue={setOriginID}
                          width="220"
                        ></JCombobox></Grid>
                      <Grid item xs={12} md={3}> <JCombobox
                        caption={translater.Translate("To")}
                        dataSource={destination}
                        valueID="id"
                        valueText="name"
                        value={destinationID}
                        setValue={setDestinationID}
                        width="220"
                      ></JCombobox></Grid>
                      <Grid item xs={12} md={2}>  
                      {/* <JDatePicker
                        caption={translater.Translate("Depart")}
                        value={departDate}
                        setValue={setDepartDate}
                        showTime={false}
                        minDate={minDate} maxDate={maxDate}
                        width="150"
                      /> */}
                      <JCombobox 
                      caption={translater.Translate("Depart")}
                      dataSource={departDates}   
                      value={departDate}
                      setValue={setDepartDate}
                      width="150"
                      valueID="dateValue"
                      valueText="displayDate"></JCombobox>
                      </Grid> {tripSelectionID == TripSelection_Return && (
                        <Grid item xs={12} md={2}>
                          {/* <JDatePicker
                          caption={translater.Translate("Return")}
                          value={returnDate}
                          setValue={setReturnDate}
                          showTime={false} 
                          minDate={minDate} maxDate={maxDate}
                          width="150"
                        /> */}
                         <JCombobox 
                          caption={translater.Translate("Return")}
                          dataSource={returnDates}   
                          value={returnDate}
                          setValue={setReturnDate}
                          width="150"
                          valueID="dateValue"
                          valueText="displayDate"></JCombobox>
                        </Grid>
                      )}
              
                      <Grid container xs={12} md={2} alignItems="flex-end"><JButton onClick={onSearch}>{translater.Translate("Search")}</JButton></Grid>
                    </Grid>                   
                  </JFormItem>
                )}

                {!showSearchPanel && (
                  <JFormItem>
                    <Stack direction="row" spacing={3}>
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={8} md={3}><JDisplayText caption={translater.Translate("From - To")}>{`${displayFromLocation} - ${displayToLocation}`}</JDisplayText></Grid>   
                        <Grid item xs={4} md={3}><JDisplayText caption={translater.Translate("No of Pax")}>{noOfPax}</JDisplayText></Grid>                     
                        <Grid item xs={6} md={3}><JDisplayText caption={translater.Translate("Depart")}>{new moment(departDate).format("ddd, DD MMMM")}</JDisplayText></Grid>
                        {tripSelectionID == TripSelection_Return && (<Grid item xs={6} md={3}><JDisplayText caption={translater.Translate("Return")}>{new moment(returnDate).format("ddd, DD MMMM")}</JDisplayText></Grid>)}
                        
                      </Grid>                     

                      <IconButton
                        onClick={() => {
                          setShowSearchPanel(true);
                        }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Stack>
                  </JFormItem>
                )}
              </JFormLayout>
            </Paper>
          </JFormItem>

          <JFormItem>
              <Grid sx={{ display: { xs: 'block', md: 'none' } }}>
                          {paymentData.departureTrip != null && (<ReservationPanel datasource={paymentData}  currencyName={currencyName} totalAmount={totalAmount}></ReservationPanel>)}
              </Grid>
          </JFormItem>

          <JFormItem>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <Stack>
                  {step === 1 && (
                    <Fragment>
                      <ReservationListItem
                        title="Depart"
                        iconName="trip_origin"
                        datasource={departureDatasource}
                        type="depart"
                        onNext={onNext}
                      ></ReservationListItem>
                    </Fragment>
                  )}

                  {step === 2 && (
                    <Fragment>
                      <ReservationListItem
                        title="Return"
                        iconName="replay"
                        datasource={returnDatasource}
                        type="return"
                        onNext={onNext}
                      ></ReservationListItem>
                       <Stack direction="row" spacing={2}>
                        <JButton buttontype="secondary" onClick={() => onBack(2)}>
                            {translater.Translate("Back")}
                          </JButton></Stack>
                    </Fragment>
                  )}

                  {step === 3 && (
                    <Fragment>
                      <Stack spacing={2}>
                        <ReservationPassengerInfo
                          datasource={passengerInfo}
                          setDatasource={setPassengerInfo}
                        ></ReservationPassengerInfo>

                        <Stack direction="row" spacing={2}>

                          <JButton buttontype="secondary" onClick={() => onBack(3)}>
                            {translater.Translate("Back")}
                          </JButton>
                          
                          {allowCashPayment && (
                            <JButton
                              onClick={() => onConfirmReservation(false)}
                            >
                               {translater.Translate("Confirm Reservation")}
                            </JButton>
                          )}
                          {allowPaymentGateway && (
                            <JButton onClick={() => onConfirmReservation(true)}>
                              {translater.Translate("Proceed to Payment")}
                            </JButton>
                          )}
                         
                        </Stack>
                      </Stack>
                    </Fragment>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={0} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                {paymentData.departureTrip != null && 
                (<ReservationPanel datasource={paymentData} currencyName={currencyName} totalAmount={totalAmount}></ReservationPanel>)}
              </Grid>
            </Grid>
          </JFormItem>
        </JFormLayout>
      </Stack>
    </Fragment>
  );
};

export default Reservation;

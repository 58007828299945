import { SendRequest } from "../hooks/new-http"

const RatePlanApi = () => {
    
  const PostRequest = SendRequest();

  const ReservationEnquiryRate = async (postData) => {
    var response = await PostRequest("RatePlan/ReservationEnquiryRate", "POST", postData);
    return response;
  }

  return { ReservationEnquiryRate };

};


export default RatePlanApi;

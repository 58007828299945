import React, { Fragment, useState,useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";

const Textbox = (props) => {
  const [initialValue, setInitialValue] = useState(props.value);

  //for numeric field put
  //type=number

  
  const type = props.isPassword ? "password" : (props.type == null ? "text" : props.type); 
  //const autoForm = props.autoForm == null ? false : props.autoForm;


  // useEffect(() => {
  //   setInitialValue(props.value);
  // }, []);

  const textboxChangeHandler = (event) => {

    if (props.fieldName == null) {
      props.setValue(event.target.value);
    } else {

      const data = props.value.find((e) => e.fieldName === props.fieldName);
      

      props.setValue((previous) => {
        return previous.filter((existingData) => existingData !== data);
      });

      data.value = event.target.value;
     
      props.setValue(((previous) => {
        return [...previous, data];
      }));

    }

//     let edited = false;
// console.log(initialValue);
//     if(event.target.value == initialValue){
//       edited = false;
//     }
//     else {
//       edited = true;
//     }

//     props.setOpenForm({
//       ...props.openForm,
//       [props.fieldName] : edited,
//     })


  };

  //props.label: caption
  //props.value: the value show in the textbox
  //props.isError: true = error
  //props.errorText: to show error message
  //props.multipleline: true = support multiple line.
  //props.isPassword: true = *****
  //props.showPrefix: true
  //props.prefixText: $
  //props.prefixPosition: start
  //props.width: '200px'
  const prefixPosition =
    props.prefixPosition == null ? "start" : props.prefixPosition;
  const width = props.width == null ? "300px" : props.width;
  const value = props.fieldName == null ? props.value : props.value.find(x => x.fieldName == props.fieldName).value;
  const showMultiline = props.multipleline;
  const placeholder = props.placeholder;
  const ShowPrefixIcon = props.showPrefixIcon ?? false;
  const PrefixIconName = props.prefixIconName;

  return (
    <Fragment>
      <Stack spacing={0.5}>
      <Typography sx={{
        fontWeight: "600", 
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
      }}>{props.caption}</Typography>
      <TextField
        size="small"
        // sx = {{ md:{ width: `${width}px` },
        // xs:{ maxWidth: "100%" }
        // }}
        sx = {{ width: { xs: "100%", md: `${width}` }
             }}
        hiddenLabel
        value={value}
        type={type}
        helperText={props.errorText}
        error={props.isError}
        multiline={showMultiline}
        placeholder={placeholder}
        autoComplete='off'
        rows={4}
        InputProps={
          props.showPrefix
            ? {
                startAdornment: (
                  <InputAdornment position={prefixPosition} sx={{ color: "black" }}>
                    {!ShowPrefixIcon && props.prefixText}
                    {ShowPrefixIcon &&  <Icon sx={
                  { color: '#6b7280' }
                    }>
                      {PrefixIconName}
                    </Icon>}
                  </InputAdornment>
                ),
              }
            : null
          }
        onChange={textboxChangeHandler}
      />
      </Stack>
    </Fragment>
  );
};

export default React.memo(Textbox);

import React, { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from '@mui/material/useMediaQuery';
import JButton from './JButton';
import { useTheme } from '@mui/material/styles';
import Translater from "../../languages/translater";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const JModal = (props) => {
  const [openModal, setOpenModal] = React.useState(props.openModal);
  const translater = Translater();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const submitCaption = props.submitCaption ?? "Submit";
  const ShowDefaultButton = props.showDefaultButton ?? true;
  const width = props.width ?? "600px";

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    props.setOpenModal(false);
  };

  const onConfirm = () =>{
    props.onConfirm();
  }


  return (
    <Fragment>
      <Dialog 
       fullScreen={fullScreen}
       maxWidth="md"
      open={props.openModal} onClose={handleClose} color="secondary" sx={{ width: '100%', p: { xs: 1, md: 5 }}}>
        <DialogTitle>{props.title}
        
         
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        
        </DialogTitle>
        <DialogContent>
            {props.children}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}

        </DialogContent>
        {ShowDefaultButton && (<DialogActions>
          <JButton onClick={onConfirm}>{translater.Translate(submitCaption)}</JButton>
          <JButton onClick={handleClose} buttontype="secondary">{translater.Translate("Cancel")}</JButton>
        </DialogActions>)}
      </Dialog>
    </Fragment>
  );
};

export default JModal;

import { Fragment } from "react";
import { Stack } from "@mui/system";
import { Paper, Icon, Avatar } from "@mui/material";
import JLabel from "../Layout/JLabel";
import JFormLayout from "../Layout/JFormLayout";
import JFormItem from "../Layout/JFormItem";

const ContactUs = (props) => {

    return <Fragment>
          <Stack spacing={1} sx={{ p: 1 }}>
            <JLabel size="15">Contact Us</JLabel>
            <Paper sx={{ p: { xs:1, md:2 }, pt: { xs: 2 } }}>   
             
            
                <JFormLayout>
                    <JFormItem>
                           <JLabel size="12">We're here to help! Whether you have a question about our charter services, or just want to say hello, we'd love to hear from you.</JLabel>
                    </JFormItem>
                    <JFormItem>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar  sx={{ bgcolor: "#2D898B" }} variant="rounded"><Icon>place</Icon></Avatar>
                            <Stack>
                                <JLabel>Location</JLabel>
                                <JLabel size="11">{props.address}</JLabel>
                            </Stack>
                        </Stack>
                    </JFormItem>
                    <JFormItem>
                    <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar  sx={{ bgcolor: "#2D898B" }} variant="rounded"><Icon>email</Icon></Avatar>
                            <Stack>
                                <JLabel>Email Us</JLabel>
                                <JLabel size="11">{props.emailAddress}</JLabel>
                            </Stack>
                        </Stack>
                    </JFormItem>
                    <JFormItem>
                    <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar  sx={{ bgcolor: "#2D898B" }} variant="rounded"><Icon>call</Icon></Avatar>
                            <Stack>
                                <JLabel>Call Us</JLabel>
                                <JLabel size="11">{props.contactNumber}</JLabel>
                            </Stack>
                        </Stack>
                    </JFormItem>
                </JFormLayout>

            </Paper>
          </Stack>
    </Fragment>


}

export default ContactUs;
import React, { Fragment, useEffect, useState } from 'react';
import JLabel from '../../Layout/JLabel';
import JFormLayout from '../../Layout/JFormLayout';
import JFormItem from '../../Layout/JFormItem';
import { Typography, Stack, Grid, Paper, Divider } from '@mui/material';
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import ReservationApi from '../../../api/reservation-api';
import JDataGrid, {
    createTableColumn,
    JDataGridProps,
  } from "../../Layout/JDataGrid";
import Translater from '../../../languages/translater';

const ReservationSuccess = () => {
    const reservationApi = ReservationApi();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const companyCode = queryParams.get("be");
    const refNumber = queryParams.get("ref");
    const sessionID = queryParams.get("session");
    const [guestName, setGuestName] = useState(null);
    const [reservationNumber, setReservationNumber] = useState(null);
    const [noOfPax, setNoOfPax] = useState(null);
    const [reservationStatusID, setReservationStatusID] = useState(null);
    const [reservationStatus, setReservationStatus] = useState(null);
    const [tripList, setTripList] = useState([]);
    const translater = Translater();

    const LoadSuccess = async () => {

        if (companyCode != null && refNumber != null) {
            var response =  await reservationApi.ProceedReservation(companyCode, refNumber, sessionID);

            console.log(response);
            if (response.success) {
                setReservationNumber(response.data.reservationReference);
                setGuestName(response.data.guestName);
                setTripList(response.data.trips);
                setReservationStatusID(response.data.reservationStatusID);
                setReservationStatus(response.data.reservationStatus);
                setNoOfPax(response.data.noOfPax);
            }
        }
    }


    useEffect(() => {
        LoadSuccess();
    },[]);

    const TableColumn = [
        createTableColumn(
          translater.Translate("Date"),
          "departDate",
          "left",
          80
        ),
        createTableColumn(
          translater.Translate("Time"),
          "departTime",
          "left",
          80
        ),
        createTableColumn(translater.Translate("From"), "fromLocation", "left", 200, false, "",false),
        createTableColumn(
          translater.Translate("To"),
          "toLocation",
          "left",
          200
        ),
        createTableColumn(translater.Translate("Fee"), "displayPrice", "left", 30),
       
      ];

    return (<Fragment>
          <Stack spacing={1} sx={{ p: 1 }}>
        <JFormLayout>
          <JFormItem>
              <JLabel>{`${translater.Translate("Reservation Status")}: ${translater.Translate(reservationStatus)}`}</JLabel>  
          </JFormItem>
          <JFormItem> 
            
            <JLabel bold={false}>{`${translater.Translate("Hello")} ${guestName}!
            ${translater.Translate("Your reservation number")}: ${reservationNumber}. 
            ${translater.Translate("Email have been sent to reservation email. Trip message will be sent to you after schedule arrangement")}`}</JLabel>

          {/* {reservationStatusID == 3 && (<Typography>
         {`Your payment have been completed. Here is your reservation number: ${reservationNumber}`}
        </Typography>)}

       {reservationStatusID == 2 && (<Typography>
         {`Your reservation have been confirmed. Here is your reservation number: ${reservationNumber}`}
        </Typography>)} */}
</JFormItem>
          <JFormItem><JLabel>{`${translater.Translate("Booking Detail")}:`}</JLabel></JFormItem>
          {/* <JFormItem>            
        <JDataGrid
            JDataGridProps={JDataGridProps(
              "scheduleID",
              TableColumn,
              tripList,
              [],
              "",
              false,
              false,
              "",
              false,
              null,
              null, "pickupDateTime", "asc", null, false
               
            )}
          />
          </JFormItem> */}

          <JFormItem>
            <Paper  sx={{ p: { xs:1, md:2 }, pt: { xs: 2 } }} elevation={3}>   
            
            <Grid container direction="row" spacing={2} xs={12} rowSpacing={1}>

              <Grid xs={1} item><JLabel>{translater.Translate("No.")}</JLabel></Grid>
              <Grid xs={9} item><JLabel>{translater.Translate("Detail")}</JLabel></Grid>
              <Grid xs={2} item><JLabel>{translater.Translate("Fee")}</JLabel></Grid>

              {tripList.map((trip, index) => (              
             <Fragment>
                  <Grid xs={1} item>
                    <JLabel bold={false}>{index + 1}</JLabel>
                  </Grid>
                  <Grid xs={9} item rowSpacing={1}>
                    <JLabel bold={false} size="12">{`Trip Date: ${trip.departDate}`}</JLabel>
                    <JLabel bold={false} size="12">{`Pickup Time: ${trip.departTime}`}</JLabel>
                    <JLabel bold={false} size="12">{`From: ${trip.fromLocation}`}</JLabel>
                    <JLabel bold={false} size="12">{`To: ${trip.toLocation}`}</JLabel>
                    <JLabel bold={false} size="12">{`Type: ${trip.vehicleTypeName}`}</JLabel>
                    <JLabel bold={false} size="12">{`Pax: ${noOfPax}`}</JLabel>
                  </Grid>
                  <Grid xs={2} item>
                    <JLabel bold={false}>{trip.displayPrice}</JLabel>
                  </Grid> 
                  
                  {tripList.length != index + 1 && (<Grid xs={12} item>
                  <Divider /></Grid>)}          
                  </Fragment>
              ))} 
              </Grid>
            </Paper>
          </JFormItem>
          <JFormItem>
            <JLabel>{`${translater.Translate("Thank you for travelling with us")}!`}</JLabel>
          </JFormItem>


        </JFormLayout>
          
       

        

</Stack>


    </Fragment>)



}

export default ReservationSuccess;
import "./App.css";
import React, { Fragment, useState, useEffect, useContext, Suspense } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography, Stack, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import CarPoolReservation from "./components/Pages/Reservation/CarPoolReservation";
import PickAndDropReservation from "./components/Pages/Reservation/PickAndDropReservation";
import TransitReservation from "./components/Pages/Reservation/TransitReservation";
import TourReservation from "./components/Pages/Reservation/TourReservation";
import ReservationSuccess from "./components/Pages/Reservation/ReservationSuccess";
import ReservationApi from "./api/reservation-api";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import BookingEngineApi from "./api/booking-engine-api";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import JButton from "./components/Layout/JButton";
import backgroundImage from "../src/assets/background.jpg";
import JNavLink from "./components/Layout/JNavLink";
import { useStore } from "./hooks-store/store";
import AuthContext from "./store/auth-context";
import Notification from "./components/Layout/Notification";
import JLoadingPanel from "./components/Layout/JLoadingPanel";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ContactUs from "./components/Pages/ContactUs";
import HomePage from "./components/Pages/HomePage";
import TestingPage from "./components/Pages/TestingPage";


function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [backgroundUrl, setBackgroundUrl] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [tripTypes, setTripTypes] = useState([]);
  const reservationApi = ReservationApi();
  const bookingEngineApi = BookingEngineApi();
  const id = queryParams.get("be");
  const [state,dispatch] = useStore();
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [carPoolDetail, setCarPoolDetail] = useState(null);
  const [pickAndDropDetail, setPickAndDropDetail] = useState(null);
  const [transitDetail, setTransitDetail] = useState(null);
  const [tourDetail, setTourDetail] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState();
  const [currencyAnchorEl, setCurrencynchorEl] = useState();
  const [languageCode, setLanguageCode] = useState("en");
  const [currencies, setCurrencies] = useState([]);
  const [currencyID, setCurrencyID] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [headerColor, setHeaderColor] = useState(null);
  const Language = authCtx.LanguageList;


  const BackgroundInit = async () => {

    var domainName = window.location.host;
    var bookingEngineCode = queryParams.get("be");


    var response = await bookingEngineApi.Init(domainName, bookingEngineCode);

    console.log(response);

    if (response.success) {
      document.title = response.data.companyName;
      authCtx.prepareBookingEngine(response.data);
      dispatch("SetCurrentBookingEngineCode", response.data.bookingEngineCode);
      setCompanyName(response.data.companyName);
      setFullAddress(response.data.fullAddress);
      setEmailAddress(response.data.emailAddress);
      setContactNumber(response.data.contactNumber);
      setTripTypes(response.data.tripTypes);
      setBackgroundUrl(response.data.backgroundUrl);
      setCurrencies(response.data.currencies);
      setHeaderColor("white");
      console.log(response.data.tripTypes);

      if (response.data.tripTypes.find(x => x.id === 1)) {        
        setPickAndDropDetail(response.data.tripTypes.find(x => x.id === 1))
      }

      if (response.data.tripTypes.find(x => x.id === 2)) {        
        setTransitDetail(response.data.tripTypes.find(x => x.id === 2))
      }

      if (response.data.tripTypes.find(x => x.id === 3)) {        
        setTourDetail(response.data.tripTypes.find(x => x.id === 3))
      }

      if (response.data.tripTypes.find(x => x.id === 5)) {        
        setCarPoolDetail(response.data.tripTypes.find(x => x.id === 5))
      }

    }
  };

  // const SignJWT = async () => {

  //   var payload = { };
  //   var secret = new TextEncoder().encode(process.env.REACT_APP_JWT_TOKEN);
  //   const alg = 'HS512'

  //   var token = await new jose.SignJWT(payload)
  //                 .setProtectedHeader({ alg })
  //                 .setIssuedAt()
  //                 .setIssuer('booking.jinspiresolutions.com')
  //                 .setAudience('booking.jinspiresolutions.com')
  //                 .setExpirationTime('2h')
  //                 .sign(secret);

  //     console.log(token);
  // }

  useEffect(() => {
      BackgroundInit();
  }, []);
 
  const onCloseNotification = () => {
    dispatch("ShowNotification", { show: false });
  };

    const handleDrawerToggle = () => {
    // if (mobileOpen) {
    //   setSidebarWidth(0);
    // } else {
    //   setSidebarWidth(drawerWidth);
    // }

    setMobileOpen(!mobileOpen);
  };

  const handleLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguage = (code) => {
    setLanguageCode(code);
    authCtx.changeLanguage(code);
    setLanguageAnchorEl(null);
  }

  const handleCurrencyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setCurrencynchorEl(event.currentTarget);
  };

  const handleCurrencyClose = () => {
    setCurrencynchorEl(null);
  };

  const handleCurrency = (id) => {
    setCurrencyID(id);
    authCtx.changeCurrency(id);
    setCurrencynchorEl(null);
  }


  return (
    <Fragment><Box sx={{
      backgroundImage: `url(${backgroundUrl})`,
      height: "100%",
      width: "100%",
      minHeight: "100vh",
      backgroundSize: "100%",
      backgroundColor: "#ededed",
      backgroundRepeat: "no-repeat",
      display: "flex",
    }}>
      <JLoadingPanel showLoadingPanel={state.isLoading} />

      {/* Header */}
      <AppBar
        color="primary"
        sx={{
          backgroundColor: {headerColor},
          display: "flex",
          maxWidth: "100%",
          // // { sm: `calc(100% - ${drawerWidth}px)` },
          // // ml: { sm: `${drawerWidth}px` },
        }}
        // md={{
        //   ml: { sm: `${drawerWidth}px` },
        // }}
      >
        <Toolbar variant="dense" sx={{ backgroundColor: {headerColor}}}>
          <Typography sx={{ fontWeight: 500 }}>{companyName}</Typography>
          <Box sx={{ flex: "1 1 auto" }} />
          <Stack spacing={2} direction="row" sx={{ display: { md: 'flex', xs: 'none' } }}>
            <JNavLink to={`homepage?be=${id}`}>Home</JNavLink>
            {tripTypes.map((tripType) => (
               <JNavLink to={`${tripType.bookingEngineUrl}?be=${id}`}>{tripType.tripTypeName}</JNavLink>
            ))}
            <JNavLink to={`contactus?be=${id}`}>Contact Us</JNavLink>
          </Stack>

          <IconButton
            size="large"
            aria-label="app-currency"
            aria-controls="app-currency"
            aria-haspopup="true"
             onClick={handleCurrencyMenu}
            color="inherit"
          >       
              <MonetizationOnIcon />
          </IconButton>

          <IconButton
            size="large"
            aria-label="app-language"
            aria-controls="app-language"
            aria-haspopup="true"
            onClick={handleLanguageMenu}
            color="inherit"
          >       
              <LanguageIcon />
          </IconButton>


          <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ mr: { xs: 1 }, display: { md: 'none', xs: 'block' } }}
            >
              <MenuIcon />
            </IconButton>


        </Toolbar>
      </AppBar>

    
      <Box
        component="main"
        sx={{
          display: "static",
          height: "100%",
          minHeight: "9vh",
          width: "100%",
          // p: { xs: 1, md: 5 },
          pt: { xs: 6 },
          // width: "100%" ,
          // md: `calc(100% - ${drawerWidth}px)`
        }}
      >
    
        {/* Body Content! */}
        <Box
          maxWidth="100%"
          sx={{
            height: "80%",
            minHeight: "87vh",
            display: "flex",
            p: 0,
          }}
        >  
        
        
       
          <Box sx={{ flex: "1 1 auto" }} />
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255,  0.8)",
              height: "80%",
              width: "1000px",
              display: "flex",
              p: 0,
            }}
          >

        <Suspense fallback={<p>Loading....</p>}>
            <Switch>
              <Route path="/pickanddrop" exact>
                <PickAndDropReservation source={pickAndDropDetail} />
              </Route>
              <Route path="/transit" exact>
                <TransitReservation source={transitDetail} />
              </Route>
              <Route path="/tour" exact>
                <TourReservation source={tourDetail} />
              </Route>
              <Route path="/carpool" exact>
                <CarPoolReservation source={carPoolDetail} />
              </Route>
              <Route path="/:triptype/success">
                <ReservationSuccess />
              </Route>
              <Route path="/contactus">
                <ContactUs address={fullAddress} emailAddress={emailAddress} contactNumber={contactNumber} />
              </Route>
              <Route path="/Testing">
                <TestingPage />
              </Route>
              <Route>
                  <HomePage tripTypes={tripTypes} />
              </Route>
            </Switch>
            </Suspense>

                 {/* Body Footer */}  

  

          </Box>

              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                  // display: { sx: "block" },
                  "& .MuiDrawer-paper": {
                    backgroundColor: "#121828",
                    boxSizing: "border-box",
                    width: 280,
                    top: 50,
                  },
                }}
              >  

                  <Stack spacing={1} direction="column">
                  <JNavLink to={`homepage?be=${id}`}>Home</JNavLink>
                  {tripTypes.map((tripType) => (
                    <JNavLink to={`${tripType.bookingEngineUrl}?be=${id}`}>{tripType.tripTypeName}</JNavLink>
                  ))}
                  <JNavLink to={`contactus?be=${id}`}>Contact Us</JNavLink>
                  </Stack>

              </Drawer>
          <Box sx={{ flex: "1 1 auto" }} />
     
          



      
         </Box>
      <Toolbar variant="dense" sx={{ backgroundColor: "black" }}      justifyContent="flex-end"
              alignItems="flex-end">
          <Box sx={{ flex: "1 1 auto" }} />

      
          <Typography variant="overline" display="block" gutterBottom color="white">
                Powered By Jinspire Solutions PTE. LTD.
            </Typography>  
             
        </Toolbar>
      
         
      </Box>

      <Menu
            id="menu-language"
            anchorEl={languageAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(languageAnchorEl)}
            onClose={handleLanguageClose}
          >
            {Language.map((lang, index) => (  
                <MenuItem button onClick={() => handleLanguage(lang.code)} selected={lang.code == languageCode}>
                  <ListItemText>              
                      {lang.name}
                  </ListItemText>
                </MenuItem>
            ))}
          </Menu>


          <Menu
            id="menu-currency"
            anchorEl={currencyAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(currencyAnchorEl)}
            onClose={handleCurrencyClose}
          >
            {currencies.map((currency, index) => (  
                <MenuItem button onClick={() => handleCurrency(currency.id)} selected={currency.id == currencyID}>
                  <ListItemText>              
                      {currency.name}
                  </ListItemText>
                </MenuItem>
            ))}
          </Menu>


      <Notification
        open={state.showNotification.show}
        onCloseNotification={onCloseNotification}
        setNotificationMessage={state.showNotification.message}
        showErrorMessage={state.showNotification.errormessage}
      />     
    </Box></Fragment>
  );
}

export default App;

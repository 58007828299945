import { initStore } from './store';


interface NotificationProps {
    show: bool,
    message: string, 
    errormessage: bool
}

const ConfigureStore = () => {
    const actions = {
        LoadingStatus: (curState, show) => {
            return { isLoading: show };
        },
        ShowNotification: (curState, param: NotificationProps) => {
            return { 
                showNotification: { 
                    show: param.show == null ? true : param.show,
                    message: param.message,
                    errormessage: param.errormessage == null ? false : param.errormessage
                }
            } 
        }      
    };
    initStore(actions, { isLoading: false , showNotification: { show: false, message: '', errormessage: false } });
};


export default ConfigureStore;
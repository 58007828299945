
import React, {Fragment} from 'react';
import Grid from "@mui/material/Grid";

const JFormItem = (props) => {

    const alignItems = props.alignItems == null ? "left" : props.alignItems;


    return <Fragment>
            <Grid item xs={12} alignItems={alignItems}>
                {props.children}
            </Grid>
    </Fragment>

}

export default JFormItem;
import { SendRequest } from "../hooks/new-http"

const BookingEngineApi = () => {
    
  const PostRequest = SendRequest();

  const Init = async (url, bookingEngineCode) => {

    var response = await PostRequest("BookingEngine/Init?domainName=" + url + "&bookingEngineCode=" + bookingEngineCode);
    return response;
  };

  const GetTermCondition = async (bookingEngineCode) => {
    var response = await PostRequest("BookingEngine/GetTermCondition?bookingEngineCode=" + bookingEngineCode);
    return response;
  }


  return { Init, GetTermCondition };

};


export default BookingEngineApi;

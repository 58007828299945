import { Fragment, useEffect, useState } from "react";
import JButton from "../Layout/JButton";
import JFormItem from "../Layout/JFormItem";
import JFormLayout from "../Layout/JFormLayout";
import { useHistory, useLocation } from "react-router-dom";
import JLabel from "../Layout/JLabel";
import { Paper } from "@mui/material";
import { Stack, Grid } from "@mui/material";

const HomePage = (props) => {

    const [tripTypes, setTripTypes] = useState(props.tripTypes);
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("be");

    useEffect(() => {
        setTripTypes(props.tripTypes);
        console.log("Yes?");
        console.log(tripTypes);
    }, [props.tripTypes])


    const Navigate = (url) => {
        history.push(url + (id != null ? "?be=" + id : ""));
    }


    return <Fragment> 
              <Stack spacing={1} sx={{ p: 1 }}>
        <JFormLayout>
            <JFormItem>

                <JLabel size="16">Experience the Convenience and Comfort of Our Charter Service</JLabel>
                <JLabel size="12" bold={false}>At our charter service, we believe that every journey should be a memorable experience. That's why we provide a range of charter services that are tailored to meet the needs of our customers.</JLabel>
              
            </JFormItem>

            <JFormItem>
                <JLabel size="14">Our Services:-</JLabel>
            </JFormItem>


            {tripTypes.map((tripType) => (

                <JFormItem>
                    <Paper sx={{ p: { xs:1, md:2 }, pt: { xs: 2 } }}>   
                        <JLabel size="14">{tripType.tripTypeName}</JLabel>
                        <br />
                        <Grid container xs={12} columnSpacing={2} rowSpacing={2} flex>
                            <Grid item xs={12} md={6}>
                                <img src="https://jinspirestorage.blob.core.windows.net/bookingengine/carpool.jpg" 
                                alt="Carpool"
                                height="100%"
                                width="100%" />
                            </Grid>
                            <Grid item xs={12} md={6} rowSpacing={2} alignItems="center" rowGap={2}>
                                <JFormLayout>
                                    <JFormItem>
                                        <JLabel size="12" bold={false}>{tripType.description}</JLabel>
                                    </JFormItem>
                                    <JFormItem alignItems="center">
                                           <JButton onClick={() => {
                                                                        Navigate(tripType.bookingEngineUrl)
                                                                    }}>Book Now</JButton>
                                    </JFormItem>
                                </JFormLayout>
                                
                                                  
                            </Grid>
                        </Grid>
               
                      </Paper>
                </JFormItem>

            ))}
            
            {/* <JFormItem>
                <h2>Airport Transit</h2>
                <div className="charter-service-content">
                <div className="charter-service-image">
                    <img src="https://dummyimage.com/400x400/000/fff" alt="Charter Service" />
                </div>
                <div className="charter-service-details">
                    <p>Traveling to and from the airport can be stressful, but with our airport transit service, you can sit back, relax, and let us take care of the rest. We provide safe, reliable, and comfortable transportation to ensure that your journey is hassle-free. Our friendly and experienced drivers are always on time and ready to assist you with your luggage. Let us help you start and end your trip on a high note - book your airport transit service with us today!</p>
                    <button>Learn More</button>
                </div>
                </div>
            </JFormItem> */}
        </JFormLayout>
        </Stack>
    </Fragment>

}

export default HomePage;
import { Grid, List, ListItem, ListItemText, Paper } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import JLabel from "../../Layout/JLabel";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import JButton from "../../Layout/JButton";
import TextField from "@mui/material/TextField";
import JFormLayout from "../../Layout/JFormLayout";
import JFormItem from "../../Layout/JFormItem";
import Translater from "../../../languages/translater";

const ReservationListItem = (props) => {
  const [datasource, setDatasource] = useState(props.datasource);
  const type = useState(props.type);
  const translater = Translater();

  useEffect(() => {
    setDatasource(props.datasource);
  }, [props.datasource]);

  const onSelected = (trip) => {
    props.onNext(trip, props.type);
  }

  return (
    <Fragment>
      
      <Paper sx={{ p: { xs:1, md:2 }, pt: { xs: 2 } }}>   

      <JFormLayout>
        <JFormItem>
          <Stack direction="row" spacing={1}><Icon sx={{ color: "#6b7280" }}>{props.iconName}</Icon><JLabel size={13}>{translater.Translate(props.title)}</JLabel></Stack>
        </JFormItem>
      
          {datasource.length == 0 && (<JFormItem><JLabel>
            {translater.Translate("No trip found on the selected date. Please select other date")}
            </JLabel></JFormItem>)}
        
      </JFormLayout>


      
      
      {/* {datasource.length > 0 && (<List sx={{ width: "92%" }}>
        {datasource.map((trip) => (
          <ListItem sx={{ p : 0, width: "100%" }}>
            <ListItemText>
              <Paper sx={{ p: 2, width: "100%" }}>
                <Stack direction="row" spacing={6} sx={{ width: "100%" }}>
                  <JLabel>{trip.departTime}</JLabel>
                  <Stack>
                    <JLabel>{trip.fromLocation}</JLabel>
                    <JLabel>{trip.fromArea}</JLabel>
                  </Stack>
                  <Icon sx={{ color: "inherit" }}>arrow_forward_ios</Icon>
                  <Stack>
                    <JLabel>{trip.toLocation}</JLabel>
                    <JLabel>{trip.toArea}</JLabel>
                  </Stack>
                  <JLabel>{trip.availableSlot} Seats</JLabel>
                  <JLabel>{trip.displayPrice}</JLabel>
                  <JButton onClick={() => onSelected(trip)}>Select</JButton>
                </Stack>
              </Paper>
            </ListItemText>
          </ListItem>
        ))}
      </List>)} */}


      {/* ICON DESIGN {datasource.length > 0 && (<List sx={{ width: "92%" }}>
        {datasource.map((trip) => (
          <ListItem sx={{ p : 0, width: "100%" }}>
            <ListItemText>
              <Paper sx={{ p: { xs:1, md:2 }, pt: { xs: 2 }, pb : { xs: 2}, width: "100%" }}>
                
                <Grid xs={12} container sx={{ width: "100%" }}>
                  <Grid container xs={8} md={6} rowSpacing={1} sx={{ pb : 2}}  alignItems="center"> 
                    <Grid item xs={2}><Icon>access_time</Icon></Grid><Grid item xs={10}>
                      <JLabel>{trip.departTime}</JLabel>
                      <JLabel size={11}>{trip.departDate}</JLabel>
                    </Grid>
                    <Grid item xs={2}><Icon>trip_origin</Icon></Grid><Grid item xs={10}><Stack>
                      <JLabel>{trip.fromLocation}</JLabel>
                      <JLabel size={11}>{trip.fromArea}</JLabel>
                    </Stack></Grid>
                    <Grid item xs={2} ><Icon>place</Icon></Grid><Grid item xs={10}><Stack>
                        <JLabel>{trip.toLocation}</JLabel>
                        <JLabel size={11}>{trip.toArea}</JLabel>
                    </Stack></Grid>
                  </Grid>
                  <Grid container xs={4}  alignItems="center">                               
                        <Grid item xs={3}><Icon>sell</Icon></Grid><Grid item xs={9}><JLabel>{trip.displayPrice}</JLabel></Grid>        
                        <Grid item xs={3}><Icon>chair</Icon></Grid><Grid item xs={9}><JLabel>{trip.availableSlot} left</JLabel></Grid>  
                        <Grid item xs={3}><Icon>directions_bus</Icon></Grid><Grid item xs={9}><JLabel>{trip.vehicleTypeName}</JLabel></Grid>          
             
                  </Grid>    
                  <Grid xs={2}>
                       {trip.availableSlot > 0 && (<JButton onClick={() => onSelected(trip)}>Select</JButton>)}
                       {trip.availableSlot <= 0 && (<JButton buttontype="error">Full</JButton>)}
                  </Grid>
                </Grid>
              </Paper>
            </ListItemText>
          </ListItem>
        ))}
      </List>)} */}


      {datasource.length > 0 && (<List sx={{ width: "92%" }}>
        {datasource.map((trip) => (
          <ListItem sx={{ p : 0, width: "100%" }}>
            <ListItemText>
              <Paper sx={{ p: { xs:1, md:2 }, pt: { xs: 2 }, pb : { xs: 2}, width: "100%" }}>
                
                <Grid xs={12} container sx={{ width: "100%" }}>
                  <Grid container xs={8} md={6} rowSpacing={1} sx={{ pb : 2}} alignItems="center">
                    <Grid item xs={3} alignItems="center"><JLabel size={11}>{translater.Translate("TIME")}</JLabel></Grid>
                    <Grid item xs={9}>                 
                      <JLabel size={11} bold={false}>{trip.displayPickupTime}</JLabel>
                      <JLabel size={10} bold={false}>{trip.displayStartDate}</JLabel>
                    </Grid>
                    <Grid item xs={3}><JLabel size={11}>{translater.Translate("FROM")}</JLabel></Grid><Grid item xs={9}><Stack>
                      <JLabel size={11} bold={false}>{trip.fromLocation}</JLabel>
                      <JLabel size={10} bold={false}>{trip.fromArea}</JLabel>
                    </Stack></Grid>
                    <Grid item xs={3}><JLabel size={11}>{translater.Translate("TO")}</JLabel></Grid><Grid item xs={9}><Stack>
                        <JLabel size={11} bold={false}>{trip.toLocation}</JLabel>
                        <JLabel size={10} bold={false}>{trip.toArea}</JLabel>
                    </Stack></Grid>
                  </Grid>
                  <Grid container xs={4} md={3}  alignItems="center">                               
                        <Grid item xs={4}><JLabel size={11}>{translater.Translate("FEE")}</JLabel></Grid><Grid item xs={8}><JLabel size={11} bold={false}>{trip.displayPrice}</JLabel></Grid>        
                        <Grid item xs={4}><JLabel size={11}>{translater.Translate("SEAT")}</JLabel></Grid><Grid item xs={8}><JLabel size={11} bold={false}>{trip.availableSlot} left</JLabel></Grid>  
                        <Grid item xs={4}><JLabel size={11}>{translater.Translate("TYPE")}</JLabel></Grid><Grid item xs={8}><JLabel size={11} bold={false}>{trip.vehicleTypeName}</JLabel></Grid>          
             
                  </Grid>    
                  <Grid xs={3}>
                       {trip.availableSlot > 0 && (<JButton onClick={() => onSelected(trip)}>{translater.Translate("Select")}</JButton>)}
                       {trip.availableSlot <= 0 && (<JButton buttontype="error">{translater.Translate("Full")}</JButton>)}
                  </Grid>
                </Grid>
              </Paper>
            </ListItemText>
          </ListItem>
        ))}
      </List>)}


      </Paper>
    </Fragment>
  );
};

export default ReservationListItem;

import React, { Fragment, useState, useEffect, useContext } from "react";
import { Stack } from "@mui/system";
import { Paper, Icon, Grid } from "@mui/material";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import JLabel from "../../Layout/JLabel";
import JFormLayout from "../../Layout/JFormLayout";
import JFormItem from "../../Layout/JFormItem";
import ReservationLocationInfo from "./ReservationLocationInfo";
import JCombobox from "../../Layout/JCombobox";
import ReservationPassengerInfo from "./ReservationPassengerInfo";
import JButton from "../../Layout/JButton";
import JTextbox from "../../Layout/JTextbox";
import JDatePicker from "../../Layout/JDatePicker";
import RatePlanApi from "../../../api/rate-plan-api";
import moment from "moment/moment";
import { useStore } from "../../../hooks-store/store";
import AuthContext from "../../../store/auth-context";
import JTimePicker from "../../Layout/JTimePicker";
import ReservationPanel from "./ReservationPanel";
import ReservationApi from "../../../api/reservation-api";

const TransitReservation = (props) => {
  const [tripSelectionID, setTripSelectionID] = useState(1);
  const [vehicleTypeID, setVehicleTypeID] = useState(1);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("be");
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [originID, setOriginID] = useState(null);
  const [destinationID, setDestinationID] = useState(null);
  const reservationApi = ReservationApi();
  const [vehicleModelID, setVehicleModelID] = useState(1);
  const [tripTypeName, setTripTypeName] = useState(null);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [locationID, setLocationID] = useState(null);
  const [transitTypeID, setTransitTypeID] = useState(null);
  const history = useHistory();
  const [noOfPax, setNoOfPax] = useState(null);
  const [additionalTripFee, setAdditionalTripFee] = useState(null);
  const [addtfCurrencyName, setAddtfCurrencyName] = useState(null);
  const [allowCashPayment, setAllowCashPayment] = useState(null);
  const [allowPaymentGateway, setAllowPaymentGateway] = useState(null);
  const [paymentData, setPaymentData] = useState({
    departureTrip: null,
    returnTrip: null,
  });
  const ratePlanApi = RatePlanApi();
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [step, setStep] = useState(1);
  const [locationList, setLocationList] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [destination, setDestination] = useState([]);
  const [state, dispatch] = useStore();
  const authCtx = useContext(AuthContext);
  const [departDate, setDepartDate] = useState(null);
  const [departPickupTime, setDepartPickupTime] = useState(null);
  const [departRemarks, setDepartRemarks] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [returnPickupTime, setReturnPickupTime] = useState(null);
  const [returnRemarks, setReturnRemarks] = useState(null);
  const [departTitle, setDepartTitle] = useState(null);
  const [returnTitle, setReturnTitle] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currencyName, setCurrencyName] = useState(null);
  const [transitLocation, setTransitLocation] = useState([
    {
      ID: Math.random().toString(),
      address: null,
    },
  ]);

  const [passengerInfo, setPassengerInfo] = useState({
    name: null,
    contactNumber: null,
  });

  const TripSelection = [
    { id: 1, name: "One-Way" },
    { id: 2, name: "Two-Way" },
  ];

  const TransitType = [
    { id: 1, name: "Arrival" },
    { id: 2, name: "Departure" },
  ];

  const onNext = async () => {
    if (step == 1) {
      let FromAreaID = null;
      let FromStateID = null;
      let FromLocationID = null;
      let ToAreaID = null;
      let ToStateID = null;
      let ToLocationID = null;

      if (originID == null) {
        dispatch("ShowNotification", {
          message: "Please select From",
          errormessage: true,
        });
        return;
      } else {
        const origin = origins.find((x) => x.id === originID);
        FromAreaID = origin.countryStateAreaID;
        FromStateID = origin.countryStateID;
        FromLocationID = origin.locationID;
      }

      if (destinationID == null) {
        dispatch("ShowNotification", {
          message: "Please select To",
          errormessage: true,
        });
        return;
      } else {
        const dest = destination.find((x) => x.id === destinationID);
        ToAreaID = dest.countryStateAreaID;
        ToStateID = dest.countryStateID;
        ToLocationID = dest.locationID;
      }

      if (departDate == null) {
        dispatch("ShowNotification", {
          message: "Please select Pickup Date",
          errormessage: true,
        });
        return;
      }

      if (tripSelectionID == 2) {
        if (returnDate == null) {
          dispatch("ShowNotification", {
            message: "Please select Return Date",
            errormessage: true,
          });
          return;
        }
      }
      //Check Rate
      const postData = {
        bookingEngineCode: id,
        tripSelectionID: tripSelectionID,
        tripTypeID: 2,
        vehicleTypeID: vehicleTypeID,
        departDate: departDate,
        returnDate: returnDate,
        fromCountryStateID: FromStateID,
        fromCountryStateAreaID: FromAreaID,
        fromLocationID: FromLocationID,
        toCountryStateID: ToStateID,
        toCountryStateAreaID: ToAreaID,
        toLocationID: ToLocationID,
        currencyID: authCtx.currencyID,
      };

      console.log(postData);
      var response = await ratePlanApi.ReservationEnquiryRate(postData);
      let totalTripAmount = 0;

      if (response.success) {
        if (response.data.length == 0) {
          dispatch("ShowNotification", {
            message: "Rate is unavailable for selected location",
          });
          return;
        }

        console.log(response.data);
        let departTrip = null;
        let returnTrip = null;

        const departResult = response.data.find((x) => x.tripSelectionID === 1);

        setDepartTitle(
          "Depart from " +
            departResult.description +
            " - " +
            departResult.displayStartDate
        );

        departTrip = {
          fromLocation: departResult.fromLocation,
          toLocation: departResult.toLocation,
          displayStartDate: departResult.displayStartDate,
          departTime: departResult.displayAmount,
          displayPrice: departResult.displayPrice,
          displayQty: departResult.displayQty,
          displayTotalAmount: departResult.displayTotalAmount,
          price: departResult.totalAmount,
          currencyName: departResult.currencyName,
          startDate: departDate,
          endDate: departDate,
          fromCountryStateID: FromStateID,
          fromCountryStateAreaID: FromAreaID,
          fromLocationID: FromLocationID,
          toCountryStateID: ToStateID,
          toCountryStateAreaID: ToAreaID,
          toLocationID: ToLocationID,
          pickupTime: departPickupTime,
          noOfDays: 0,
          remarks: "",
        };

        if (tripSelectionID == 2) {
          const returnResult = response.data.find(
            (x) => x.tripSelectionID === 2
          );

          setReturnTitle(
            "Return from " +
              departResult.description +
              " - " +
              departResult.displayTripDate
          );
          returnTrip = {
            fromLocation: returnResult.fromLocation,
            toLocation: returnResult.toLocation,
            departDate: returnResult.displayTripDate,
            departTime: returnResult.displayAmount,
            displayPrice: returnResult.displayAmount,
            price: returnResult.totalAmount,
            currencyName: returnResult.currencyName,
            startDate: returnDate,
            fromCountryStateID: FromStateID,
            fromCountryStateAreaID: FromAreaID,
            toCountryStateID: ToStateID,
            toCountryStateAreaID: ToAreaID,
            pickupTime: returnPickupTime,
            noOfDays: 0,
            remarks: "",
          };
        }

        totalTripAmount = totalTripAmount + departTrip.price;
        setPaymentData({
          ...paymentData,
          departureTrip: departTrip,
          returnTrip: returnTrip,
          totalAmount: totalTripAmount,
        });

        setTotalAmount(totalTripAmount);
        setCurrencyName(departResult.currencyName);
      } else {
      }

      setStep(2);
    } else if (step == 2) {

      let trip = paymentData.departureTrip;
      let extraCharges = 0;
      let extraQty = 0;
      let pickupLocation = null;
      let dropOffLocation = null;

      if (transitTypeID == 1) {
        dropOffLocation = transitLocation;
      }
      else{
        pickupLocation = transitLocation;
      }

      if (transitLocation.length > 1) {
          var extraTrip = transitLocation.length - 1;
          
          extraCharges = extraCharges + (extraTrip * additionalTripFee);
          extraQty = extraQty + extraTrip;
      }

      trip = {...trip, 
        pickupLocation: pickupLocation,
        dropoffLocation: dropOffLocation,
        pickupTime: departPickupTime,
        displayPickupTime: departPickupTime,
        remarks: departRemarks,
        extraQty: extraQty,
        extraFee: extraCharges
      }

      setPaymentData({...paymentData, 
      departureTrip: trip});

      // if (tripSelectionID == 1) {
      //     setStep(4);
      // }
      // else {
      //     setStep(3);
      // }

      setStep(3);
    } else {
      setStep(4);
    }
  };

  const onBack = () => {
    if (step == 4) {
      setStep(3);
    } else if (step == 3) {
      setStep(2);
    } else {
      setStep(1);
    }
  };

  useEffect(() => {
    if (props.source != null) {
      console.log(props.source);
      setAllowCashPayment(props.source.allowCashPayment);
      setAllowPaymentGateway(props.source.allowPaymentGateway);
      setMinDate(moment().add(1, "day"));
      setMaxDate(moment().add(props.source.bookingInAdvanced, "month"));
      setTripTypeName(props.source.tripTypeName);
      setVehicleTypes(props.source.vehicleTypes);
      setAdditionalTripFee(props.source.additionalTripFee);
      setAddtfCurrencyName(props.source.currencyName);
      setLocationList(props.source.origins ?? []);
      setOrigins([]);
      //setOrigins(props.source.origins ?? []);
    }
  }, [props.source]);

  useEffect(() => {
    if (transitTypeID != null) {
      if (transitTypeID == 1) {
        console.log(locationList.filter((x) => x.locationID !== null));
        setOrigins(locationList.filter((x) => x.locationID !== null));
      } else {
        setOrigins(locationList.filter((x) => x.locationID === null));
      }
    }
  }, [transitTypeID]);

  useEffect(() => {
    if (originID != null) {
      console.log(originID);
      if (origins.find((x) => x.id === originID) == null) {
        setDestination([]);
      } else {
        setDestination(origins.find((x) => x.id === originID).destinations);
      }
    }
  }, [originID]);

  const onConfirmReservation = async (ProceedPayment) => {
    if (passengerInfo.name == null) {
      dispatch("ShowNotification", {
        message: "Please fill in Full Name",
        errormessage: true,
      });
      return;
    }

    if (passengerInfo.contactNumber == null) {
      dispatch("ShowNotification", {
        message: "Please fill in Contact Number",
        errormessage: true,
      });
      return;
    }

    if (passengerInfo.email == null) {
      dispatch("ShowNotification", {
        message: "Please fill in Email",
        errormessage: true,
      });
      return;
    }

    if (passengerInfo.agreement != true) {
      dispatch("ShowNotification", {
        message: "Please tick to agree the Term and Condition",
        errormessage: true,
      });
      return;
    }

    let postData = {
      ...paymentData,
      bookingEngineCode: state.currentBookingEngineCode,
      contactNumber: passengerInfo.contactNumber,
      passengerName: passengerInfo.name,
      email: passengerInfo.email,
      tripTypeID: 2,
      currencyID: authCtx.currencyID,
      proceedPayment: ProceedPayment,
      noOfPax: noOfPax,
      vehicleTypeID: vehicleTypeID,
      tripSelectionID: tripSelectionID,
    };

    console.log(passengerInfo);
    console.log(postData);

    var response = await reservationApi.ConfirmReservation(postData);

    if (response.success) {
      if (ProceedPayment) {
        //history.push(response.data);
        window.location.replace(response.data);
      } else {
        //Confirmation Page
        history.push("transit/success?be=" + id + "&ref=" + response.key);
      }
    }
  };

  return (
    <Fragment>
      <Stack spacing={1} sx={{ p: 1 }}>
        <JFormLayout>
          <JFormItem>
            <JLabel>{tripTypeName}</JLabel>
          </JFormItem>
          {/* {step == 1 && (<JFormItem><Paper sx={{ p: 2 }}>
            <JFormLayout>
            <JFormItem>
                <Stack direction="row" spacing={1}><Icon sx={{ color: "#6b7280" }}>info</Icon><JLabel size={13}>Trip Info</JLabel></Stack>
            </JFormItem>
            <JFormItem>
          <JCombobox
                        caption="Type"
                        dataSource={TransitType}
                        valueID="id"
                        valueText="name"
                        value={transitTypeID}
                        setValue={setTransitTypeID}
                        width="250"
                      ></JCombobox>
          </JFormItem>
          <JFormItem>
                <JCombobox
                        caption="Vehicle Type"
                        dataSource={vehicleModel}
                        valueID="id"
                        valueText="name"
                        value={vehicleModelID}
                        setValue={setVehicleModelID}
                        width="250"
                ></JCombobox>
          </JFormItem>
          <JFormItem>
            <JTextbox caption="No of Passenger" width="250px"></JTextbox>
          </JFormItem>
          <JFormItem>
                <JCombobox
                        caption="Location"
                        dataSource={transitLocation}
                        valueID="id"
                        valueText="name"
                        value={locationID}
                        setValue={setLocationID}
                        width="250"
                ></JCombobox>
          </JFormItem>
          <JFormItem>
            <JTextbox caption="Flight Info" width="250px"></JTextbox>
          </JFormItem>
          <JFormItem>
            <JTextbox caption={transitTypeID == 1 ? "Arrival Time" : "Departure Time"} width="250px"></JTextbox>
          </JFormItem>         
          <JFormItem>
            <JTextbox caption={transitTypeID == 1 ? "Origin" : "Destination"}  width="250px"></JTextbox>
          </JFormItem>
          </JFormLayout></Paper>
          </JFormItem>)} */}

          {step == 1 && (
            <JFormItem>
              <Paper sx={{ p: 2 }}>
                <JFormLayout>
                  <JFormItem>
                    <Stack direction="row" spacing={1}>
                      <Icon sx={{ color: "#6b7280" }}>info</Icon>
                      <JLabel size={13}>Trip Info</JLabel>
                    </Stack>
                  </JFormItem>
                  <JFormItem>
                    <Grid container rowSpacing={2} flex>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                          caption="Trip Type"
                          dataSource={TransitType}
                          valueID="id"
                          valueText="name"
                          value={transitTypeID}
                          setValue={setTransitTypeID}
                          width="220"
                        ></JCombobox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                          caption="Vehicle Type"
                          dataSource={vehicleTypes}
                          valueID="id"
                          valueText="name"
                          value={vehicleTypeID}
                          setValue={setVehicleTypeID}
                          width="220"
                        ></JCombobox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JTextbox
                          caption="No of Passenger"
                          value={noOfPax}
                          setValue={setNoOfPax}
                          type="number"
                          width="100px"
                        ></JTextbox>
                      </Grid>
                    </Grid>
                  </JFormItem>
                  <JFormItem>
                    <Grid container rowSpacing={2} flex>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                          caption="From"
                          dataSource={origins}
                          valueID="id"
                          valueText="name"
                          value={originID}
                          setValue={setOriginID}
                          width="220"
                        ></JCombobox>{" "}
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                          caption="To"
                          dataSource={destination}
                          valueID="id"
                          valueText="name"
                          value={destinationID}
                          setValue={setDestinationID}
                          width="220"
                        ></JCombobox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JDatePicker
                          caption="Depart Date"
                          showTime={false}
                          value={departDate}
                          setValue={setDepartDate}
                          width="220"
                          minDate={minDate}
                          maxDate={maxDate}
                        ></JDatePicker>
                      </Grid>
                      {/* {tripSelectionID == 2 && (<Grid item xs={12} md={3}>
                      <JDatePicker caption="Return Date" showTime={false} value={returnDate} setValue={setReturnDate} width="220" minDate={minDate} maxDate={maxDate}></JDatePicker>  
                      </Grid>)}            */}
                    </Grid>
                  </JFormItem>
                </JFormLayout>
              </Paper>
            </JFormItem>
          )}
<JFormItem>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              {step == 2 && (
                <Paper sx={{ p: { xs: 1, md: 2 }, pt: { xs: 2 } }}>
                  <JFormLayout>
                    <JFormItem>
                      <JLabel>{departTitle}</JLabel>
                    </JFormItem>
                    <JFormItem>
                      <JLabel
                        size={10}
                      >{`Please take note: each addition address will be charged for ${addtfCurrencyName} ${additionalTripFee}`}</JLabel>
                    </JFormItem>
                    <JFormItem>
                      <JTimePicker
                        caption="Pickup Time"
                        value={departPickupTime}
                        setValue={setDepartPickupTime}
                        halfHourOnly={true}
                        width="220"
                      ></JTimePicker>
                    </JFormItem>
                    <JFormItem>
                      <ReservationLocationInfo
                        title={
                          transitTypeID == 1
                            ? "Drop Off Address"
                            : "Pick Up Address"
                        }
                        iconName={transitTypeID == 1 ? "replay" : "trip_origin"}
                        locations={transitLocation}
                        setLocations={setTransitLocation}
                      />
                    </JFormItem>
                    <JFormItem>
                      <JTextbox
                        caption="Request"
                        value={departRemarks}
                        setValue={setDepartRemarks}
                        multipleline="true"
                        width="500px"
                      ></JTextbox>
                    </JFormItem>
                  </JFormLayout>
                </Paper>
              )}

              {step == 3 && (
                <JFormItem>
                  <ReservationPassengerInfo
                    datasource={passengerInfo}
                    setDatasource={setPassengerInfo}
                  ></ReservationPassengerInfo>
                </JFormItem>
              )}
            </Grid>
            <Grid
              item
              xs={0}
              md={3}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {paymentData.departureTrip != null && (
                <ReservationPanel
                  datasource={paymentData}
                  currencyName={currencyName}
                  totalAmount={totalAmount}
                ></ReservationPanel>
              )}
            </Grid>
          </Grid>
      </JFormItem>
          <JFormItem>
            <Stack direction="row" spacing={2}>
              <JButton onClick={onBack} buttontype="secondary">
                Back
              </JButton>

              {step != 3 && <JButton onClick={onNext}>Next</JButton>}
              {step == 3 && (
                <Fragment>
                                 {/* {allowCashPayment && ( */}
                            <JButton
                              onClick={() => onConfirmReservation(false)}>
                              Confirm Reservation
                            </JButton>
                          {/* )}
                          {allowPaymentGateway && (  */}
                            <JButton onClick={() => onConfirmReservation(true)}>
                              Proceed to Payment
                            </JButton>
                          {/* )}  */}
                </Fragment>
              )}
            </Stack>
          </JFormItem>
        </JFormLayout>
      </Stack>
    </Fragment>
  );
};

export default TransitReservation;

import React, { Fragment, useState, useEffect } from "react";
import JTextbox from "../../Layout/JTextbox";
import JPhoneInput from "../../Layout/JPhoneInput";
import JLabel from "../../Layout/JLabel";
import JFormLayout from "../../Layout/JFormLayout";
import JFormItem from "../../Layout/JFormItem";
import { Icon, Stack, Paper, Button, TextField } from "@mui/material";
import JCheckbox from "../../Layout/JCheckbox";
import JModal from "../../Layout/JModal";
import Translater from "../../../languages/translater";
import BookingEngineApi from "../../../api/booking-engine-api";
import { useStore } from "../../../hooks-store/store";

const ReservationPassengerInfo = (props) => {
  const [passengerName, setPassengerName] = useState(props.datasource.name);
  const [contactNumber, setContactNumber] = useState(props.datasource.contactNumber);
  const [email, setEmail] = useState(props.datasource.email);
  const [agreement, setAgreement] = useState(false);
  const [termCondition, setTermCondition] = useState("Term and Condition from Booking Setting will Show here");
  const [openModal, setOpenModal] = useState(false);
  const [state, dispatch] = useStore();
  const bookingEngineApi = BookingEngineApi();
  const translater = Translater();

  useEffect(() => {
    props.setDatasource({
      name: passengerName,
      contactNumber: contactNumber,
      agreement: agreement,
      email: email
    });
  }, [passengerName, contactNumber, agreement, email]);

  const ShowTermCondition = async () => {

    var response =  await bookingEngineApi.GetTermCondition(state.currentBookingEngineCode);

    if (response.success) {
      setTermCondition(response.data);
      setOpenModal(true)
    }
    else{
      dispatch("ShowNotification", response.message);
    }

  }

  return (
    <Fragment>
      <Paper sx={{ p : 2 }}>
      <JFormLayout main={true}>
        <JFormItem>
          <Stack direction="row" spacing={1}><Icon sx={{ color: "#6b7280" }}>account_circle</Icon><JLabel>
            {translater.Translate("Passenger Info")}</JLabel>
            </Stack>
        </JFormItem>
        <JFormItem>
          <JTextbox
            caption={translater.Translate("Full Name")}
            value={passengerName}
            setValue={setPassengerName}
          ></JTextbox>
        </JFormItem>
        <JFormItem>
          <JPhoneInput
            caption={translater.Translate("Contact No")}
            value={contactNumber}
            setValue={setContactNumber}
          ></JPhoneInput>
        </JFormItem>
        <JFormItem>
          <JTextbox
            caption={translater.Translate("Email")}
            value={email}
            setValue={setEmail}
          ></JTextbox>
        </JFormItem>
        <JFormItem>
            <Stack direction="row" spacing={0}>
              <JCheckbox caption={translater.Translate("I have agreed on the")} isCheck={agreement} setIsCheck={setAgreement}></JCheckbox>
              <Button size="medium" sx={{
                  fontSize: "11pt",
                  fontWeight: 0,
                textTransform: "none",
                fontFamily:
                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }} onClick={() => { ShowTermCondition(); }}>{translater.Translate("Term and Condition")}</Button>
            </Stack>
        </JFormItem>
      </JFormLayout></Paper>

      <JModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        showDefaultButton={false}
        title="Term and Condition">
        <TextField
          value={termCondition}
          multiline={true}
          rows={20}
          sx={{ width: "500px" }}
        />
      </JModal>


    </Fragment>
  );
};

export default ReservationPassengerInfo;

import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Stack } from '@mui/system';
import { Paper, Icon, Grid } from '@mui/material';
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import JLabel from '../../Layout/JLabel';
import JFormLayout from '../../Layout/JFormLayout';
import JFormItem from '../../Layout/JFormItem';
import ReservationLocationInfo from './ReservationLocationInfo';
import JCombobox from '../../Layout/JCombobox';
import ReservationPassengerInfo from './ReservationPassengerInfo';
import JButton from '../../Layout/JButton';
import JTextbox from '../../Layout/JTextbox';
import JDatePicker from '../../Layout/JDatePicker';
import moment from 'moment/moment';
import { useStore } from "../../../hooks-store/store";
import RatePlanApi from '../../../api/rate-plan-api';
import AuthContext from '../../../store/auth-context'
import ReservationPanel from "./ReservationPanel";
import ReservationApi from "../../../api/reservation-api";
import Reservation from './CarPoolReservation';
import JTimePicker from "../../Layout/JTimePicker";

const TourReservation = (props) => {

  const [state, dispatch] = useStore();
  const ratePlanApi = RatePlanApi();
  const reservationApi = ReservationApi();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("be");
  const authCtx = useContext(AuthContext);
  const [tripTypeName, setTripTypeName] = useState(null);
  const [vehicleTypeID, setVehicleTypeID] = useState(1);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [noOfPax, setNoOfPax] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [origins, setOrigins] = useState([]);
  const [destination, setDestination] = useState([]);
  const [originID, setOriginID] = useState(null);
  const [destinationID, setDestinationID] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pickupLocation, setPickupLocation] = useState([
    {
      ID: Math.random().toString(),
      address: null,
    },
  ]);
  const [departTitle, setDepartTitle] = useState(null);
  const [departPickupTime, setDepartPickupTime] = useState(null);
  const [additionalTripFee, setAdditionalTripFee] = useState(null);
  const [addtfCurrencyName, setAddtfCurrencyName] = useState(null);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [attractionLocations, setAttractionLocation] = useState([]);
  const [locationID, setLocationID] = useState(null);
    const [transitTypeID, setTransitTypeID] = useState(1);
    const [allowCashPayment, setAllowCashPayment] = useState(null);
    const [allowPaymentGateway, setAllowPaymentGateway] = useState(null);
    const [step, setStep] = useState(1);
    const [passengerInfo, setPassengerInfo] = useState({
      name: null,
      contactNumber: null,
    });
    const [paymentData, setPaymentData] = useState({
      departureTrip: null,
      returnTrip: null,
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [currencyName, setCurrencyName] = useState(null);
    const [departRemarks, setDepartRemarks] = useState(null);

    const onNext = async () => {
      if (step == 1) {

        let FromAreaID = null;
        let FromStateID = null;
        let FromLocationID = null;
        let ToAreaID = null;
        let ToStateID = null;
        let ToLocationID = null;
  
        if (originID == null) {
          dispatch("ShowNotification", {
            message: "Please select From",
            errormessage: true,
          });
          return;
        } else {
          const origin = origins.find((x) => x.id === originID);
          FromAreaID = origin.countryStateAreaID;
          FromStateID = origin.countryStateID;
          FromLocationID = origin.locationID;
        }
  
        if (destinationID == null) {
          dispatch("ShowNotification", {
            message: "Please select To",
            errormessage: true,
          });
          return;
        } else {
          const dest = destination.find((x) => x.id === destinationID);
          ToAreaID = dest.countryStateAreaID;
          ToStateID = dest.countryStateID;
          ToLocationID = dest.locationID;
        }
  
        if (startDate == null) {
          dispatch("ShowNotification", {
            message: "Please select Trip Start Date",
            errormessage: true,
          });
          return;
        }

          
        if (endDate == null) {
          dispatch("ShowNotification", {
            message: "Please select Trip End Date",
            errormessage: true,
          });
          return;
        }
  

        //Check Rate
        const postData = {
          bookingEngineCode: id,
          tripSelectionID: 1,
          tripTypeID: 3,
          vehicleTypeID: vehicleTypeID,
          departDate: startDate,
          returnDate: endDate,
          fromCountryStateID: FromStateID,
          fromCountryStateAreaID: FromAreaID,
          fromLocationID: FromLocationID,
          toCountryStateID: ToStateID,
          toCountryStateAreaID: ToAreaID,
          toLocationID: ToLocationID,
          currencyID: authCtx.currencyID,
        };
  
        console.log(postData);
        var response = await ratePlanApi.ReservationEnquiryRate(postData);
        let totalTripAmount = 0;
  
        if (response.success) {
          if (response.data.length == 0) {
            dispatch("ShowNotification", {
              message: "Rate is unavailable for selected location",
            });
            return;
          }
  
          console.log(response.data);
          let departTrip = null;
          let returnTrip = null;
  
          const departResult = response.data.find((x) => x.tripSelectionID === 1);
  
          setDepartTitle(
            "Depart from " +
              departResult.description +
              " - " +
              departResult.displayStartDate
          );
  
          departTrip = {
            fromLocation: departResult.fromLocation,
            toLocation: departResult.toLocation,
            displayStartDate: departResult.displayStartDate,
            departTime: departResult.displayAmount,
            displayPrice: departResult.displayPrice,
            displayQty: departResult.displayQty,
            displayTotalAmount: departResult.displayTotalAmount,
            price: departResult.totalAmount,
            currencyName: departResult.currencyName,
            startDate: departResult.startDate,
            endDate: departResult.endDate,
            fromCountryStateID: FromStateID,
            fromCountryStateAreaID: FromAreaID,
            fromLocationID: FromLocationID,
            toCountryStateID: ToStateID,
            toCountryStateAreaID: ToAreaID,
            toLocationID: ToLocationID,
            pickupTime: departPickupTime,
            noOfDays: 0,
            remarks: "",
          };
   

        totalTripAmount = totalTripAmount + departTrip.price;
        setPaymentData({
          ...paymentData,
          departureTrip: departTrip,
          returnTrip: returnTrip,
          totalAmount: totalTripAmount,
        });

        setTotalAmount(totalTripAmount);
        setCurrencyName(departResult.currencyName);
      }
          setStep(2);
      }
      else if (step == 2) {

        let trip = paymentData.departureTrip;
        let extraCharges = 0;
        let extraQty = 0;
  
        if (pickupLocation.length > 1) {
            var extraTrip = pickupLocation.length - 1;
            
            extraCharges = extraCharges + (extraTrip * additionalTripFee);
            extraQty = extraQty + extraTrip;
        }
  
        trip = {...trip, 
          pickupLocation: pickupLocation,
          dropoffLocation: null,
          pickupTime: departPickupTime,
          displayPickupTime: departPickupTime,
          remarks: departRemarks,
          extraQty: extraQty,
          extraFee: extraCharges
        }
  
        setPaymentData({...paymentData, 
        departureTrip: trip});



        setStep(3);
      }
      else {
          setStep(4);
      }
  }

  const onBack = () => {
      if (step == 4) {
        setStep(3);
      } else if (step == 3) {
          setStep(2);
      } else {
          setStep(1);
      }
  }

  
  useEffect(() => {
    if (props.source != null) {
      console.log(props.source);
      setAllowCashPayment(props.source.allowCashPayment);
      setAllowPaymentGateway(props.source.allowPaymentGateway);
      setMinDate(moment().add(1, "day"));
      setMaxDate(moment().add(props.source.bookingInAdvanced, "month"));
      setTripTypeName(props.source.tripTypeName);
      setVehicleTypes(props.source.vehicleTypes);
      setAdditionalTripFee(props.source.additionalTripFee);
      setAddtfCurrencyName(props.source.currencyName);
      setOrigins(props.source.origins ?? []);
    }
  }, [props.source]);

  useEffect(() => {
    if (originID != null) {
      console.log(originID);
      if (origins.find((x) => x.id === originID) == null) {
        setDestination([]);
      } else {
        setDestination(origins.find((x) => x.id === originID).destinations);
      }
    }
  }, [originID]);

  const onConfirmReservation = async (ProceedPayment) => {
    if (passengerInfo.name == null) {
      dispatch("ShowNotification", {
        message: "Please fill in Full Name",
        errormessage: true,
      });
      return;
    }

    if (passengerInfo.contactNumber == null) {
      dispatch("ShowNotification", {
        message: "Please fill in Contact Number",
        errormessage: true,
      });
      return;
    }

    if (passengerInfo.email == null) {
      dispatch("ShowNotification", {
        message: "Please fill in Email",
        errormessage: true,
      });
      return;
    }

    if (passengerInfo.agreement != true) {
      dispatch("ShowNotification", {
        message: "Please tick to agree the Term and Condition",
        errormessage: true,
      });
      return;
    }

    let postData = {
      ...paymentData,
      bookingEngineCode: state.currentBookingEngineCode,
      contactNumber: passengerInfo.contactNumber,
      passengerName: passengerInfo.name,
      email: passengerInfo.email,
      tripTypeID: 3,
      currencyID: authCtx.currencyID,
      proceedPayment: ProceedPayment,
      noOfPax: noOfPax,
      vehicleTypeID: vehicleTypeID,
      tripSelectionID: 1,
    };

    console.log(passengerInfo);
    console.log(postData);

    var response = await reservationApi.ConfirmReservation(postData);

    if (response.success) {
      if (ProceedPayment) {
        //history.push(response.data);
        window.location.replace(response.data);
      } else {
        //Confirmation Page
        history.push("tour/success?be=" + id + "&ref=" + response.key);
      }
    }
  };



    return <Fragment>
               <Stack spacing={1} sx={{ p: 1 }}>
        <JFormLayout>
          <JFormItem>
            <JLabel>{tripTypeName}</JLabel>
          </JFormItem>
          {step == 1 && (

<JFormItem>
              <Paper sx={{ p: 2 }}>
                <JFormLayout>
                  <JFormItem>
                    <Stack direction="row" spacing={1}>
                      <Icon sx={{ color: "#6b7280" }}>info</Icon>
                      <JLabel size={13}>Trip Info</JLabel>
                    </Stack>
                  </JFormItem>
                  <JFormItem>
                    <Grid container rowSpacing={2} flex>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                          caption="Vehicle Type"
                          dataSource={vehicleTypes}
                          valueID="id"
                          valueText="name"
                          value={vehicleTypeID}
                          setValue={setVehicleTypeID}
                          width="220"
                        ></JCombobox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JTextbox
                          caption="No of Passenger"
                          value={noOfPax}
                          setValue={setNoOfPax}
                          type="number"
                          width="100px"
                        ></JTextbox>
                      </Grid>
                    </Grid>
                  </JFormItem>
                  <JFormItem>
                    <Grid container rowSpacing={2} flex>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                          caption="From"
                          dataSource={origins}
                          valueID="id"
                          valueText="name"
                          value={originID}
                          setValue={setOriginID}
                          width="220"
                        ></JCombobox>{" "}
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JCombobox
                          caption="To"
                          dataSource={destination}
                          valueID="id"
                          valueText="name"
                          value={destinationID}
                          setValue={setDestinationID}
                          width="220"
                        ></JCombobox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JDatePicker
                          caption="Trip Start Date"
                          showTime={false}
                          value={startDate}
                          setValue={setStartDate}
                          width="220"
                          minDate={minDate}
                          maxDate={maxDate}
                        ></JDatePicker>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <JDatePicker
                          caption="End Date"
                          showTime={false}
                          value={endDate}
                          setValue={setEndDate}
                          width="220"
                          minDate={minDate}
                          maxDate={maxDate}
                        ></JDatePicker>
                      </Grid>
                      {/* {tripSelectionID == 2 && (<Grid item xs={12} md={3}>
                      <JDatePicker caption="Return Date" showTime={false} value={returnDate} setValue={setReturnDate} width="220" minDate={minDate} maxDate={maxDate}></JDatePicker>  
                      </Grid>)}            */}
                    </Grid>
                  </JFormItem>
                </JFormLayout>
              </Paper>
            </JFormItem>


          // <JFormItem><Paper sx={{ p: 2 }}>
          //   <JFormLayout>
          //   <JFormItem>
          //       <Stack direction="row" spacing={1}><Icon sx={{ color: "#6b7280" }}>info</Icon><JLabel size={13}>Trip Info</JLabel></Stack>
          //   </JFormItem>
          // <JFormItem>
          //       <JCombobox
          //               caption="Vehicle Type"
          //               dataSource={vehicleModel}
          //               valueID="id"
          //               valueText="name"
          //               value={vehicleModelID}
          //               setValue={setVehicleModelID}
          //               width="250"
          //       ></JCombobox>
          // </JFormItem>
          // <JFormItem>
          //   <JTextbox caption="No of Passenger" width="250px"></JTextbox>
          // </JFormItem>
          // <JFormItem>
          //       <JCombobox
          //               caption="Location"
          //               dataSource={attractionLocations}
          //               valueID="id"
          //               valueText="name"
          //               value={locationID}
          //               setValue={setLocationID}
          //               width="250"
          //       ></JCombobox>
          // </JFormItem>         
          // </JFormLayout></Paper>
          // </JFormItem>
          
          )
          
          
          }

          <JFormItem>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>

          {step == 2 && (
          
          <Paper sx={{ p: { xs: 1, md: 2 }, pt: { xs: 2 } }}>
          <JFormLayout>
            <JFormItem>
              <JLabel>{departTitle}</JLabel>
            </JFormItem>
            <JFormItem>
              <JLabel
                size={10}
              >{`Please take note: each addition address will be charged for ${addtfCurrencyName} ${additionalTripFee}`}</JLabel>
            </JFormItem>
            <JFormItem>
              <JTimePicker
                caption="Pickup Time"
                value={departPickupTime}
                setValue={setDepartPickupTime}
                halfHourOnly={true}
                width="220"
              ></JTimePicker>
            </JFormItem>
                <JFormItem>
                      <ReservationLocationInfo
                        title="Pick Up Address"
                        iconName="trip_origin"
                        locations={pickupLocation}
                        setLocations={setPickupLocation}
                      />
                    </JFormItem>    <JFormItem>
                      <JTextbox
                        caption="Request"
                        value={departRemarks}
                        setValue={setDepartRemarks}
                        multipleline="true"
                        width="500px"
                      ></JTextbox>
                    </JFormItem>
                  </JFormLayout>
                </Paper>)}
          

             {step == 3 && (<JFormItem>
                    <ReservationPassengerInfo
                          datasource={passengerInfo}
                          setDatasource={setPassengerInfo}
                    ></ReservationPassengerInfo>
            </JFormItem>)}

            </Grid>
            <Grid
              item
              xs={0}
              md={3}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {paymentData.departureTrip != null && (
                <ReservationPanel
                  datasource={paymentData}
                  currencyName={currencyName}
                  totalAmount={totalAmount}
                ></ReservationPanel>
              )}
            </Grid>
          </Grid>
     </JFormItem>
            <JFormItem>
                <Stack direction="row" spacing={2}>
                    <JButton onClick={onBack} buttontype="secondary">Back</JButton>
                    
                    {step != 3 && <JButton onClick={onNext}>Next</JButton>}
                    {step == 3 && (<Fragment>
                                           {/* {allowCashPayment && ( */}
                                           <JButton
                              onClick={() => onConfirmReservation(false)}>
                              Confirm Reservation
                            </JButton>
                          {/* )}
                          {allowPaymentGateway && (  */}
                            <JButton onClick={() => onConfirmReservation(true)}>
                              Proceed to Payment
                            </JButton>
                          {/* )}  */}
                   </Fragment>)}
                    
                </Stack>
            </JFormItem>

          </JFormLayout>
          </Stack>
    </Fragment>


}

export default TourReservation;
import React, { Fragment, useState, useEffect } from 'react';
import JLabel from '../../Layout/JLabel';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import Translater from '../../../languages/translater';

const ReservationPanel = (props) => {

    const [departureTrip, setDepartureTrip] = useState(props.datasource);
    const [returnTrip, setReturnTrip] = useState(props.datasource);
    const [currencyName, setCurrencyName] = useState(props.currencyName);
    const [totalAmount, setTotalAmount] = useState(props.totalAmount);
    const translater = Translater();

    useEffect(() => {
        setDepartureTrip(props.datasource.departureTrip);
        setReturnTrip(props.datasource.returnTrip);
        setCurrencyName(props.currencyName);
        setTotalAmount(props.totalAmount);
    }, [props.datasource]);

    console.log(props.datasource)

    return <Fragment>
            <Paper>
       <Stack spacing={2} sx={{ p : 2 }}>

       <Stack direction="row" spacing={1}><Icon sx={{ color: "#6b7280" }}>summarize</Icon>
            <JLabel size={13}>{translater.Translate("Summary")}</JLabel></Stack>

        {departureTrip != null && (<Fragment><Stack rowGap={1}><JLabel size={11}>{translater.Translate("DEPART")}</JLabel>
        <JLabel size={10}>{`From: ${departureTrip.fromLocation}`}</JLabel>
        <JLabel size={10}>{`To: ${departureTrip.toLocation}`}</JLabel>
        <JLabel size={10}>{`Date: ${departureTrip.displayStartDate}`}</JLabel>
        {departureTrip.displayPickupTime != null && <JLabel size={11}>{`Pickup Time: ${departureTrip.displayPickupTime}`}</JLabel>}
        {departureTrip.displayQty == 1 && (<JLabel size={10}>{`Fee: ${departureTrip.displayPrice}`}</JLabel>)}
        {departureTrip.displayQty > 1 && (<JLabel size={10}>{`Fee: ${departureTrip.displayPrice} x ${departureTrip.displayQty} = ${departureTrip.displayTotalAmount}`}</JLabel>)}
        {departureTrip.extraFee > 0 && (<JLabel size={10}>{`Additional Fee: ${departureTrip.extraFee}`}</JLabel>)}
        </Stack></Fragment>)}

        


        {returnTrip != null && (<Fragment><Stack><JLabel  size={12}>{translater.Translate("Return")}</JLabel>
        <JLabel size={11}>{`${returnTrip.fromLocation} to ${returnTrip.toLocation}`}</JLabel>
        <JLabel size={11}>{`${returnTrip.displayStartDate} - ${returnTrip.displayPickupTime}`}</JLabel>
        <JLabel size={10}>{`${returnTrip.displayPrice} x ${returnTrip.displayQty} = ${returnTrip.displayTotalAmount}`}</JLabel>
        {returnTrip.extraFee > 0 && (<JLabel size={10}>{`Additional Trip Fee: ${returnTrip.extraFee}`}</JLabel>)}</Stack>
        </Fragment>)}
       
        
        <JLabel>{`${translater.Translate("Total")}: ${currencyName} ${totalAmount}`}</JLabel>

       </Stack>
       </Paper>
    </Fragment>

}

export default React.memo(ReservationPanel);
import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import ActionItem from "./ActionItem";
import JButton from "./JButton";
import JTextbox from "./JTextbox";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import JBadge from "./JBadge";
import TextField from "@mui/material/TextField";
import CheckBox from "@mui/material/Checkbox";
import Translater from "../../languages/translater";
import moment from "moment/moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f4f6",
    color: "#374151",
    fontWeight: "600",
    fontSize: "12pt",
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12pt",
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    component: "h1",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const createTableColumn = (
  caption,
  textField,
  align = "left",
  width = 200,
  showbadge = false,
  badgecolor = "red",
  multiline = false,
  datatype = "string"
) => {
  return { caption, textField, width, align, showbadge, badgecolor, multiline, datatype };
};

export const createActionItem = (
  name,
  url,
  iconName = "",
  navLink = true,
  actionName = "",
  showConfirmation = true,
  confirmationMessage = "Are you sure you want to delete?",
  validator = [] // { "fieldname":"", "value":"", "type":"==/!=" }
) => {
  return {
    name,
    url,
    iconName,
    navLink,
    actionName,
    showConfirmation,
    confirmationMessage,
    validator
  };
};

export const JDataGridProps = (
  keyName,
  tableColumn,
  dataSource,
  actionItemData = [],
  newUrl = "",
  showNewButton = true,
  showSearchText = true,
  badgeColorFieldName = "",
  showRowSelection = false,
  selectedRow = [],
  setSelectedRow,
  orderBy = null,
  orderType = null,
  onNewAction = null,
  showPagination = true,
) => {
  return {
    keyName,
    tableColumn,
    dataSource,
    actionItemData,
    newUrl,
    showNewButton,
    showSearchText,
    badgeColorFieldName,
    showRowSelection,
    selectedRow,
    setSelectedRow,
    orderBy,
    orderType,
    onNewAction, showPagination
  };
};

const DataGrid = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorel, setAnchorEl] = useState(null);
  const history = useHistory();
  const [selected, setSelected] = useState(props.JDataGridProps.selectedRow);
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [order, setOrder] = React.useState(props.JDataGridProps.orderType);
  const [orderBy, setOrderBy] = React.useState(props.JDataGridProps.orderBy);
  const [displayData, setDisplayData] = useState([]);
  const translater = Translater();

  //props.showNewButton == true
  //props.url == to navigate to other page when trigger add
  const showNewButton = props.JDataGridProps.showNewButton;
  const tableColumn = props.JDataGridProps.tableColumn;
  const dataSource = props.JDataGridProps.dataSource;
  const keyName = props.JDataGridProps.keyName;
  const badgeColorFieldName = props.JDataGridProps.badgeColorFieldName;
  const actionItemData = props.JDataGridProps.actionItemData;
  const newUrl = props.JDataGridProps.newUrl;
  const showRowSelection = props.JDataGridProps.showRowSelection;
  const showSearchText = props.JDataGridProps.showSearchText;
  const showPagination = props.JDataGridProps.showPagination;
  const size = "small";

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const open = Boolean(anchorel);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const NavigateTo = () => {
    if (props.JDataGridProps.onNewAction == null) {
      history.push(newUrl);
    }
    else {
      props.JDataGridProps.onNewAction();
    }
  };

  const onActionItemClick = (actionName, key) => {
    props.onActionItemClick(actionName, key);
  };

  const isSelected = (keyname, id) => {
    //   console.log(selected.find(x => x[keyname] == id));
    return selected.find((x) => x[keyname] === id) == null ? false : true;
  };

  const handleRowClick = (keyname, row) => {
    if (showRowSelection) {
      let selectedData;
      let newSelected = selected;
      if (selected.length > 0) {
        selectedData = selected.find((x) => x[keyname] === row[keyname]);

        if (selectedData == null) {
          newSelected = [...newSelected, row];
        } else {
          newSelected = newSelected.filter(
            (schedule) => schedule !== selectedData
          );
        }
      } else {
        newSelected = [...newSelected, row];
        // setSelected((prev) => {
        //   return [...prev, row];
        // });
      }

      if (newSelected.length === dataSource.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }

      setSelected(newSelected);
      props.JDataGridProps.setSelectedRow(newSelected);
    }
  };

  const handleAllRowClick = () => {
    if (selectAll) {
      setSelected([]);
      props.JDataGridProps.setSelectedRow([]);
    } else {
      setSelected(dataSource);
      props.JDataGridProps.setSelectedRow(dataSource);
    }
    setSelectAll(!selectAll);
  };

  const handleRequestSort =
    (fieldname) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === fieldname && order === "asc";
      const searchOrder = isAsc ? "desc" : "asc";

      setOrder(searchOrder);
      setOrderBy(fieldname);
      searchData(searchOrder, fieldname);
    };

  useEffect(() => {
    const SearchText = () => {
      searchData(order, orderBy);
    };
    SearchText();
  }, [searchText]);

  useEffect(() => {
    const InitialLoad = () => {
      let orderType = order;
      let orderByField = orderBy;

      if(orderType == null) {
        orderType = "asc";
        setOrder("asc");
      }

      if(orderBy == null) {
        orderByField = tableColumn[0].textField;
        setOrderBy(tableColumn[0].textField);
      }
      setSearchText("");
      searchData(orderType, orderByField);
      setSelectAll(false);
      setSelected([]);
    };
    InitialLoad();
  }, [dataSource]);

  const searchData = (searchOrder, searchOrderBy) => {
    let filterdata = [];
    if (searchText != null && searchText != "") {
      filterdata = dataSource.filter((row) => {
        return tableColumn
          .map((column) => {
            const text = row[column.textField];
            return String(text)
              .toLowerCase()
              .includes(searchText.toLowerCase());
            //console.log(row[column].value);
          })
          .includes(true);
      });
    } else {
      filterdata = dataSource;
    }

    const result = filterdata.sort((a, b) => {
      const first = a[searchOrderBy];
      const second = b[searchOrderBy];

      if (searchOrder == "asc") {
        if (first < second) {
          return -1;
        }

        if (first > second) {
          return 1;
        }

        return 0;
      } else {
        if (first < second) {
          return 1;
        }

        if (first > second) {
          return -1;
        }

        return 0;
      }
    });

    setDisplayData(result);
  };

  return (
    <Fragment>
      {[showSearchText, showNewButton].includes(true) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            padding: "10px 0px",
          }}
        >
          {showSearchText && (
            <JTextbox
              value={searchText}
              setValue={setSearchText}
              placeholder={`${translater.Translate("Search")}...`}
              showPrefix={true}
              showPrefixIcon={true}
              prefixIconName="search"
              width="500px"
            ></JTextbox>
          )}
          <Box sx={{ flex: "1 1 auto" }} />
          {showNewButton && <JButton onClick={NavigateTo}>{translater.Translate("Add")}</JButton>}
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table" size={size}>
          <TableHead>
            <StyledTableRow key="jtable-header0">
              {showRowSelection && (
                <StyledTableCell align="center" sx={{ width: "20px" }}>
                  <CheckBox
                    checked={selectAll}
                    onChange={handleAllRowClick}
                    inputProps={{
                      "aria-labelledby": "enhanced-table-checkbox-all",
                    }}
                  />
                </StyledTableCell>
              )}

              <StyledTableCell align="center" sx={{ width: "20px" }}>
                {translater.Translate("No.")}
              </StyledTableCell>
              {tableColumn.map((column) => (
                <StyledTableCell
                  key={column.caption}
                  align={column.align}
                  sortDirection={orderBy === column.textField ? order : false}
                >
                  {/* {column.caption} */}

                  <TableSortLabel
                    active={orderBy === column.textField}
                    direction={orderBy === column.textField ? order : "asc"}
                    onClick={handleRequestSort(column.textField)}
                  >
                    {column.caption}
                    {/* {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null} */}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              {actionItemData.length > 0 && (
                <StyledTableCell align="center">{translater.Translate("Action")}</StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {displayData.length > 0 &&
              displayData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(keyName, row[keyName]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      key={row[keyName]}
                      onClick={() => handleRowClick(keyName, row)}
                      xs={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {showRowSelection && (
                        <TableCell align="center" sx={{ width: "30px" }}>
                          <CheckBox
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}

                      <StyledTableCell align="center" sx={{ width: "30px" }}>
                        {index + 1}
                      </StyledTableCell>
                      {tableColumn.map((column) => {
                        
                        let textValue = row[column.textField];
                        //convert to display date form
                        if (column.datatype == 'date') {
                          textValue = moment(textValue).format("DD/MM/YYYY");
                        }
                        else if (column.datatype == 'datetime') {
                          textValue = moment(textValue).format("DD/MM/YYYY HH:mm");
                        }


                        return (<StyledTableCell
                          align={column.align}
                          sx={{ width: `${column.width}px` }}
                        >
                       
                          {column.showbadge && (
                            <JBadge
                              label={textValue}
                              color={row[column.badgecolor]}
                              width={column.width}
                            />
                          )}
                          {!column.showbadge &&
                            !column.multiline &&
                            textValue}
                          {!column.showbadge && column.multiline && (
                            <TextField
                              value={textValue}
                              variant="outlined"
                              multiline
                              sx={{
                                width: "100%",
                                "& .MuiInputBase-input": {
                                  position: "relative",
                                  fontSize: "10pt",
                                  padding: "0px",
                                  margin: "0px",
                                  fontFamily:
                                    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                                  width: "100%",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#fff",
                                    width: "auto",
                                    padding: "0px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#fff",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#fff",
                                  },
                                },
                              }}
                              InputProps={{
                                readOnly: true,
                                margin: 0,
                              }}
                            ></TextField>
                          )}
                        </StyledTableCell>)
                })}

                      {actionItemData.length > 0 && (
                        <StyledTableCell align="center">
                          <ActionItem
                            id={row[keyName]}
                            actionItem={actionItemData}
                            onActionItemClick={onActionItemClick}
                            data={row}
                          />
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>

      {dataSource.length == 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            padding: "30px 0px",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <Typography>{translater.Translate("No record found")}</Typography>
          <Box sx={{ flex: "1 1 auto" }} />
        </Box>
      )}

    {showPagination && <TablePagination
            rowsPerPageOptions={[10, 30, 50]}
            component="div"
            count={displayData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
    </Fragment>
  );
};

export default DataGrid;

import { useContext } from 'react';
//import AuthContext from "../store/auth-context";
import { useStore } from "../hooks-store/store";
import * as jose from 'jose'

export const SendRequest = () => {

  const dispatch = useStore()[1];
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
 
  //const authCtx = useContext(AuthContext);

  // setIsLoading(true);
  // setError(null);
 let result;

  const postRequest = async (
    endpoint,
    method = "GET",
    postData = null,
    authorization = true
  ) => {

    dispatch("LoadingStatus", true);

    
    var payload = { };
    var secret = new TextEncoder().encode(process.env.REACT_APP_JWT_TOKEN);
    const alg = 'HS512'

    var token = await new jose.SignJWT(payload)
                  .setProtectedHeader({ alg })
                  .setIssuedAt()
                  .setIssuer('booking.jinspiresolutions.com')
                  .setAudience('booking.jinspiresolutions.com')
                  .setExpirationTime('1m')
                  .sign(secret);

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":'http://192.168.1.76:2000'
    };

    if (authorization === true) {
      headers = {
        "Content-Type": "application/json",
        Authorization: token,
        "Access-Control-Allow-Origin":'http://192.168.1.76:3000'
      };
    }

    try {

      const url =  process.env.REACT_APP_API_ENDPOINT;
      //const url = "https://vrpapi20221124160317.azurewebsites.net/";
      
      const response = await fetch(url + "api/" + endpoint, {
        method: method ? method : "GET",
        // mode: 'cors',
        // credentials: 'include',
        headers: headers,
        body: postData ? JSON.stringify(postData) : null,
      });
      // console.log("Callinnnnng API;");
      // console.log(response);

      if (!response.ok) {

        if(response.status == 401) {
        
          throw new Error("Unauthorized Error. Please login again");
        
        }else {

          // result = response.json();
          // dispatch("ShowNotification", {
          //   message: result.message,
          //   errormessage: true,
          // });

          throw new Error("Request failed");
        }
      }

      result = response.json();

    } catch (err) {

      result = {
        success: false,
        message: err.message || "Something went wrong!"
      }

    }

    // setIsLoading(false);
    dispatch("LoadingStatus", false);
    return result;
  };

return postRequest;

};

import React, { useContext } from "react";
import chinese from "./chinese";
import english from "./english";
import AuthContext from "../store/auth-context";

const Translater = () => {

  const Translate = (sentence) => {

    const authCtx = useContext(AuthContext);
    const language = authCtx.language;

    var translate = null;

    switch (language) {
      case "cn":
        translate = chinese;
        break;
      default:
        translate = english;
        break;
    }

    var result =
      translate[sentence] !== undefined ? translate[sentence] : sentence;

    return result;

  };

  const TranslateList = (source, id, name) => {

    let translatedList = [];

    source.map((sc) => {
      console.log(sc[name]);
      translatedList.push({
        [id]: sc[id],
        [name]: Translate(sc[name])
      });

    });


    console.log(translatedList);
    return translatedList;

  }

  return { Translate, TranslateList };
};

export default Translater;
